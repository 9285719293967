import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import moment from 'moment';

import axios from 'axios';
import configData from '../Config';

import Title from '../Title';

function UserManagement() {
    const [{ user, project }, dispatch] = useStateValue();

    const [users, setUsers] = useState([]);

    const [result, setResult] = useState(null);
    const [option, setOption] = useState(null);

    useEffect(() => {
        console.log('Trying to get users');

        axios.post(configData.CONTROLLERURL + configData.ADMINGETUSERS, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Get users data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setUsers(res.data)
            }
        }).catch(() => {
        })
    }, [project]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddUser />)
    }

    const editButton = (user) => {
        setOption(<EditUser user={user} />)
    }

    const viewButton = (user) => {
        setOption(<ViewUser user={user} />)
    }

    const addUser = (email) => {
        console.log('Trying to add user');

        setResult(null);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            email: email
        }

        axios.post(configData.CONTROLLERURL + configData.ADMINADDUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Add user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    data.id = res.data.id;
                    setOption(null)
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error');
            }
        }).catch(() => {
            setResult('Error');
        })
    }

    const updateUser = (id, email, username, status) => {
        console.log('Trying to update user');

        setResult(null);

        const data = {
            id: id,
            email: email,
            username: username,
            status: status
        }

        axios.post(configData.CONTROLLERURL + configData.ADMINUPDATEUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Update user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setOption(null)
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error');
            }
        }).catch(() => {
            setResult('Error');
        })
    }

    function AddUser() {
        const [email, setEmail] = useState('');

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add User</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>User Email</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addUser(email)}>Save</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');
        const [status, setStatus] = useState('');
        const [username, setUsername] = useState('');

        useEffect(() => {
            setId(user.id);
            setEmail(user.email);
            setStatus(user.status);
            setUsername(user.name);
        }, [user]);

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit User</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Select status</option>
                                    <option value='A'>Active</option>
                                    <option value='P'>Pending</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateUser(id, email, username, status)}>Update</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');

        useEffect(() => {
            setId(user.id)
            setEmail(user.email)
        }, [user]);

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>{id}</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>{email}</div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='users'>
            <Title text='Users' />

            <button className='button' onClick={addButton}>Add User</button>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Id</th><th>Email</th><th>Username</th><th>Last login</th><th>Status</th></tr></thead>
                <tbody>
                    {
                        users.map((user, index) => {
                            let parsed = JSON.parse(user.token);

                            return (
                                <tr key={'user' + index}>
                                    <td><div className='table_button' onClick={() => editButton(user)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(user)}>View</div></td>
                                    <td>{user.id}</td>
                                    <td>{user.email}</td>
                                    <td>{user.name}</td>
                                    <td>{moment.unix(parsed.ts).format('DD-MM-YYYY HH:mm:ss')}</td>
                                    <td>{user.status}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default UserManagement;