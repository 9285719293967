import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';

import Title from './Title';

import './Inventory.css';

function Inventory() {
    const [{ user, project }, dispatch] = useStateValue();

    const [search, setSearch] = useState('');
    const [filtereditems, setFiltereditems] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        const filtered = project.inventory.filter((inventory) =>
            inventory.name.toLowerCase().includes(search.toLowerCase()) ||
            inventory.code.toLowerCase().includes(search.toLowerCase())
        );

        setFiltereditems(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddItem />);
    }

    const editButton = (item) => {
        setOption(<EditItem item={item} />);
    }

    const removeButton = (item) => {
        setOption(<RemoveItem item={item} />);
    }

    function AddItem() {
        const [code, setCode] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addItem = (code, name) => {
            console.log('Trying to add item');

            if (!name) {
                setResult('Missing info')
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.inventory.some(item => item.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Item with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: user.userid,
                projectid: project.id,
                code: code,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDITEM, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add item data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ITEM',
                            item: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Item</div>
                        <div className='modal_rowsection'>
                            <div className='modal_row'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='modal_row'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addItem(code, name)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditItem({ item }) {
        const [id, setId] = useState('');
        const [code, setCode] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(item.id);
            setCode(item.code);
            setName(item.name);
        }, [item]);

        const updateItem = (id, code, name) => {
            console.log('Trying to update item');

            if (!name) {
                setResult('Missing info')
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.inventory.some(item => item.name === trimmedName && item.id !== id);
            if (isNameDuplicate) {
                setResult('Item with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: user.userid,
                projectid: project.id,
                id: id,
                code: code,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEITEM, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update item data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ITEM',
                            item: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Item</div>
                        <div className='modal_rowsection'>
                            <div className='modal_row'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='modal_row'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateItem(id, code, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(item)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveItem({ item }) {
        const [id, setId] = useState('');
        const [code, setCode] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(item.id);
            setCode(item.code);
            setName(item.name);
        }, [item]);

        const removeItem = (id) => {
            console.log('Trying to remove item');

            if (project.salesinvoices) {
                const hasSalesInvoiceData = project.salesinvoices.some((invoice) => {
                    return invoice.items && invoice.items.some((item) => item.itemid === id);
                });
                if (hasSalesInvoiceData) {
                    setResult('Project has sales invoice data related to ' + name);
                    return;
                }
            }

            if (project.purchaseinvoices) {
                const hasPurchaseInvoicesData = project.purchaseinvoices.some((invoice) => {
                    return invoice.items && invoice.items.some((item) => item.itemid === id);
                });
                if (hasPurchaseInvoicesData) {
                    setResult('Project has purchase invoice data related to ' + name);
                    return;
                }
            }
            
            setLoading(true);

            const data = {
                projectuserid: user.userid,
                projectid: project.id,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEITEM, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove item data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ITEM',
                            itemid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeItem(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const calculateActualQuantity = (item) => {
        const totalQuantitySold = project.salesinvoices.reduce((total, invoice) => {
            const soldItem = invoice.items.find((soldItem) => soldItem.itemid === item.id);
            const soldQuantity = soldItem ? parseFloat(soldItem.itemquantity) : 0;
            return total + soldQuantity;
        }, 0);

        const totalQuantityPurchased = project.purchaseinvoices.reduce((total, invoice) => {
            const purchasedItem = invoice.items.find((purchasedItem) => purchasedItem.itemid === item.id);
            const purchasedQuantity = purchasedItem ? parseFloat(purchasedItem.itemquantity) : 0;
            return total + purchasedQuantity;
        }, 0);

        const actualQuantity = totalQuantityPurchased - totalQuantitySold;

        return actualQuantity >= 0 ? actualQuantity : 0;
    };

    return (
        <div className='accounts'>
            <Title text='Inventory' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Item</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className='buttonsection'></div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Code</th><th>Name</th><th>Quantity</th></tr></thead>
                <tbody>
                    {
                        filtereditems.map((item, index) => (
                            <tr key={'item' + index}>
                                <td><div className='table_button' onClick={() => editButton(item)}>Edit</div></td>
                                <td><div>{item.code}</div></td>
                                <td><div>{item.name}</div></td>
                                <td><div>{calculateActualQuantity(item)}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Inventory;