import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useStateValue } from './StateProvider';
import configData from './Config.json';

import backbutton from './assets/authscreen_backbutton.png';
import passwordhide from './assets/authscreen_hidepassword.png';
import passwordshow from './assets/authscreen_showpassword.png';

import Spinner from './Spinner';

import './AuthScreen.css';

//v20231130

function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

function AuthScreen() {
    const [{ user }, dispatch] = useStateValue();

    const [activeForm, setActiveForm] = useState('Login');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isChangePassword = params.get('changepassword') === 'true';
    const [userid, setUserid] = useState(user.userid);
    const [usertoken, setToken] = useState(user.usertoken);

    useEffect(() => {
        if (isChangePassword) {
            setActiveForm('ChangePassword');
            setUserid(params.get('uid'))
            setToken(params.get('t'))
        }
    }, [isChangePassword]);

    const storeuser = async (userid, usertoken) => {
        try {
            const jsonValue = JSON.stringify({ 'userid': userid, 'usertoken': usertoken })
            await localStorage.setItem('user', jsonValue)
        } catch (e) {
            console.log(e)
        }
    }

    const handleBackButton = () => {
        setActiveForm('Login');
    }

    const handleRegisterButton = () => {
        setActiveForm('Register')
    }

    const handleForgotButton = () => {
        setActiveForm('Forgot')
    }

    function Login() {
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [passwordvisible, setPasswordvisible] = useState(false);
        const [rememberMe, setRememberMe] = useState(false);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState();

        const handleLogin = () => {
            setResult();

            if (!email) {
                setResult('Please enter email');
                return;
            } else if (!isValidEmail(email)) {
                setResult('Please enter a valid email address');
                return;
            }
            if (!password) {
                setResult('Please enter password');
                return;
            }

            setLoading(true);

            const data = {
                email: email,
                password: password
            };

            axios.post(configData.CONTROLLERURL + configData.LOGIN, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }).then(res => {
                console.log('Login data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: "ADD_USER",
                            user: {
                                userid: res.data.userid,
                                usertoken: res.data.usertoken,
                                useremail: email,
                                usertype: res.data.usertype
                            }
                        })
                        if (rememberMe) storeuser(res.data.userid, res.data.usertoken);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                setLoading(false);
            }).catch(() => {
                setResult('Not connected');
                setLoading(false);
            })
        };


        return (
            <div className='authscreen_container'>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                    </div>
                    <div className='authscreen_rowsection'>
                        <div className='authscreen_title'>Sign-in</div>
                    </div>
                    <div className='authscreen_rowsection'>
                    </div>
                </div>
                <h5 className='authscreen_label'>E-mail</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <input
                            className='authscreen_input'
                            value={email}
                            type='text'
                            onChange={(e) => setEmail(e.target.value)}
                            autoCapitalize='none'
                        />
                    </div>
                </div>
                <h5 className='authscreen_label'>Password</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_passwordinput'>
                        <input
                            className='authscreen_input'
                            type={passwordvisible ? 'text' : 'password'}
                            autoComplete='off'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleLogin();
                                }
                            }}
                        />
                    </div>
                    <div className='authscreen_passwordicon'>
                        <button className='authscreen_showhidebutton' onClick={() => setPasswordvisible(!passwordvisible)}>
                            {passwordvisible ? (
                                <img className='authscreen_showhidebuttonicon' src={passwordhide} alt="Hide Password" />
                            ) : (
                                <img className='authscreen_showhidebuttonicon' src={passwordshow} alt="Show Password" />
                            )}
                        </button>
                    </div>
                </div>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <input
                            className='authscreen_remembermecheckbox'
                            type='checkbox'
                            id='rememberMe'
                            name='rememberMe'
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                        />
                        <label htmlFor='rememberMe'>Remember me</label>
                    </div>
                </div>
                {loading && <Spinner />}
                {
                    result &&
                    <div className='authscreen_resultcontainer'>
                        <div className='authscreen_result'>{result}</div>
                    </div>
                }
                <div className='authscreen_buttonscontainer'>
                    <button className='authscreen_button' onClick={handleLogin}>Login</button>
                    <button className='authscreen_button' onClick={handleRegisterButton}>Register</button>
                    <button className='authscreen_button' onClick={handleForgotButton}>Forgot Password</button>
                </div>
            </div>
        )
    };

    function Register() {
        const [username, setUsername] = useState('');
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [passwordvisible, setPasswordvisible] = useState(false);
        const [confirmpassword, setConfirmpassword] = useState('');
        const [confirmpasswordvisible, setConfirmpasswordvisible] = useState(false);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState();

        const handleRegister = () => {
            setResult();

            if (!username) {
                setResult('Please enter username');
                return;
            }
            if (!email) {
                setResult('Please enter email');
                return;
            } else if (!isValidEmail(email)) {
                setResult('Please enter a valid email address');
                return;
            }
            if (!password) {
                setResult('Please enter password');
                return;
            }
            if (password !== confirmpassword) {
                setResult('Passwords don\'t match');
                return;
            }
            setLoading(true);

            const data = {
                username,
                email: email,
                password: password
            };

            axios.post(configData.CONTROLLERURL + configData.REGISTERUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }).then(res => {
                console.log('Login data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    setResult(res.data.data)
                }
                else {
                    setResult('Please try again later or contact the Admin.')
                }
                setLoading(false);
            }).catch(() => {
                setResult('Not connected');
                setLoading(false);
            })
        };

        return (
            <div className='authscreen_container'>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <div className='authscreen_backbutton' onClick={handleBackButton}>
                            <img className='authscreen_backbuttonicon' src={backbutton} />
                        </div>
                    </div>
                    <div className='authscreen_rowsection'>
                        <div className='authscreen_title'>Register</div>
                    </div>
                    <div className='authscreen_rowsection'>
                    </div>
                </div>
                <h5 className='authscreen_label'>Username</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <input
                            className='authscreen_input'
                            value={username}
                            type='text'
                            onChange={(e) => setUsername(e.target.value)}
                            autoCapitalize='none'
                        />
                    </div>
                </div>
                <h5 className='authscreen_label'>E-mail</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <input
                            className='authscreen_input'
                            value={email}
                            type='text'
                            onChange={(e) => setEmail(e.target.value)}
                            autoCapitalize='none'
                        />
                    </div>
                </div>
                <h5 className='authscreen_label'>Password</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_passwordinput'>
                        <input
                            className='authscreen_input'
                            type={passwordvisible ? 'text' : 'password'}
                            autoComplete='off'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='authscreen_passwordicon'>
                        <button className='authscreen_showhidebutton' onClick={() => setPasswordvisible(!passwordvisible)}>
                            {passwordvisible ? (
                                <img className='authscreen_showhidebuttonicon' src={passwordhide} alt="Hide Password" />
                            ) : (
                                <img className='authscreen_showhidebuttonicon' src={passwordshow} alt="Show Password" />
                            )}
                        </button>
                    </div>
                </div>
                <h5 className='authscreen_label'>Confirm Password</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_passwordinput'>
                        <input
                            className='authscreen_input'
                            type={confirmpasswordvisible ? 'text' : 'password'}
                            autoComplete='off'
                            value={confirmpassword}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                        />
                    </div>
                    <div className='authscreen_passwordicon'>
                        <button className='authscreen_showhidebutton' onClick={() => setConfirmpasswordvisible(!confirmpasswordvisible)}>
                            {confirmpasswordvisible ? (
                                <img className='authscreen_showhidebuttonicon' src={passwordhide} alt="Hide Password" />
                            ) : (
                                <img className='authscreen_showhidebuttonicon' src={passwordshow} alt="Show Password" />
                            )}
                        </button>
                    </div>
                </div>
                {loading && <Spinner />}
                {
                    result &&
                    <div className='authscreen_resultcontainer'>
                        <div className='authscreen_result'>{result}</div>
                    </div>
                }
                <div className='authscreen_buttonscontainer'>
                    <button className='authscreen_button' onClick={handleRegister}>Register</button>
                </div>
            </div>
        )
    };

    function ForgotPassword() {
        const [email, setEmail] = useState('');
        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState();

        const handleForgotPassword = () => {
            setResult();

            if (!email) {
                setResult('Please enter email');
                return;
            } else if (!isValidEmail(email)) {
                setResult('Please enter a valid email address');
                return;
            }

            setLoading(true);

            const data = {
                email: email
            };

            axios.post(configData.CONTROLLERURL + configData.FORGOTPASSWORD, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }).then(res => {
                console.log('Login data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    setResult(res.data.data)
                }
                else {
                    setResult('Please try again later or contact the Admin.')
                }
                setLoading(false);
            }).catch(() => {
                setResult('Not connected');
                setLoading(false);
            })
        };

        return (
            <div className='authscreen_container'>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <div className='authscreen_backbutton' onClick={handleBackButton}>
                            <img className='authscreen_backbuttonicon' src={backbutton} />
                        </div>
                    </div>
                    <div className='authscreen_rowsection'>
                        <div className='authscreen_title'>Forgot Password</div>
                    </div>
                    <div className='authscreen_rowsection'>
                    </div>
                </div>
                <h5 className='authscreen_label'>E-mail</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                        <input
                            className='authscreen_input'
                            value={email}
                            type='text'
                            onChange={(e) => setEmail(e.target.value)}
                            autoCapitalize='none'
                        />
                    </div>
                </div>
                {loading && <Spinner />}
                {
                    result &&
                    <div className='authscreen_resultcontainer'>
                        <div className='authscreen_result'>{result}</div>
                    </div>
                }
                <div className='authscreen_buttonscontainer'>
                    <button className='authscreen_button' onClick={handleForgotPassword}>Send Request</button>
                </div>
            </div>
        )
    };

    function ChangePassword() {
        const [password, setPassword] = useState('');
        const [passwordvisible, setPasswordvisible] = useState(false);
        const [confirmpassword, setConfirmpassword] = useState('');
        const [confirmpasswordvisible, setConfirmpasswordvisible] = useState(false);

        const [button, setButton] = useState(false);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState();

        useEffect(() => {
            setLoading(true);
            setButton(false);
            const data = {
                userid: userid,
                usertoken: usertoken
            }

            axios.post(configData.CONTROLLERURL + configData.VALIDATEUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                },
            }).then(res => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setResult('Token Validated');
                        setButton(false);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            setButton(true);
                            setLoading(false);
                            setResult(res.data.data)
                        }, 5000);
                    }
                }
            }).catch(() => {

            })
        }, [])

        const handleChangePassword = () => {
            setResult();

            if (!password) {
                setResult('Please enter password');
                return;
            }
            if (!confirmpassword) {
                setResult('Please enter confirm password');
                return;
            }
            if (password != confirmpassword) {
                setResult('Passwords don\'t match');
                return;
            }

            setLoading(true);

            const data = {
                userid: userid,
                usertoken: usertoken,
                newpassword: password
            };

            axios.post(configData.CONTROLLERURL + configData.CHANGEPASSWORD, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }).then(res => {
                console.log('Change password data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    setResult(res.data.data)
                }
                else {
                    setResult('Please try again later or contact the Admin.')
                }
                setLoading(false);
            }).catch(() => {
                setResult('Not connected');
                setLoading(false);
            })
        };

        return (
            <div className='authscreen_container'>
                <div className='authscreen_row'>
                    <div className='authscreen_rowsection'>
                    </div>
                    <div className='authscreen_rowsection'>
                        <div className='authscreen_title'>Change Password</div>
                    </div>
                    <div className='authscreen_rowsection'>
                    </div>
                </div>
                <h5 className='authscreen_label'>Password</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_passwordinput'>
                        <input
                            className='authscreen_input'
                            type={passwordvisible ? 'text' : 'password'}
                            autoComplete='off'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='authscreen_passwordicon'>
                        <button className='authscreen_showhidebutton' onClick={() => setPasswordvisible(!passwordvisible)}>
                            {passwordvisible ? (
                                <img className='authscreen_showhidebuttonicon' src={passwordhide} alt="Hide Password" />
                            ) : (
                                <img className='authscreen_showhidebuttonicon' src={passwordshow} alt="Show Password" />
                            )}
                        </button>
                    </div>
                </div>
                <h5 className='authscreen_label'>Confirm Password</h5>
                <div className='authscreen_row'>
                    <div className='authscreen_passwordinput'>
                        <input
                            className='authscreen_input'
                            type={confirmpasswordvisible ? 'text' : 'password'}
                            autoComplete='off'
                            value={confirmpassword}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                        />
                    </div>
                    <div className='authscreen_passwordicon'>
                        <button className='authscreen_showhidebutton' onClick={() => setConfirmpasswordvisible(!confirmpasswordvisible)}>
                            {confirmpasswordvisible ? (
                                <img className='authscreen_showhidebuttonicon' src={passwordhide} alt="Hide Password" />
                            ) : (
                                <img className='authscreen_showhidebuttonicon' src={passwordshow} alt="Show Password" />
                            )}
                        </button>
                    </div>
                </div>
                {loading && <Spinner />}
                {
                    result &&
                    <div className='authscreen_resultcontainer'>
                        <div className='authscreen_result'>{result}</div>
                    </div>
                }
                <div className='authscreen_buttonscontainer'>
                    <button className='authscreen_button' onClick={handleChangePassword} disabled={button}>Change Password</button>
                </div>
            </div>
        )
    };

    return (
        <div className='authscreen'>
            {activeForm === 'ChangePassword' && <ChangePassword />}
            {activeForm === 'Login' && <Login />}
            {activeForm === 'Register' && <Register />}
            {activeForm === 'Forgot' && <ForgotPassword />}
        </div>
    );
}

export default AuthScreen;