import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';

import axios from 'axios';
import configData from './Config';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import projects_logo from './assets/projects_logo.png';

import './Projects.css';

function Projects() {
    const [{ user, version, projects }, dispatch] = useStateValue();

    const [listprojects, setListprojects] = useState([]);

    const [search, setSearch] = useState('');
    const [progress, setProgress] = useState(null);

    const [loading, setLoading] = useState(null);

    const navigation = useNavigate();

    useEffect(() => {
        const filtered = projects.filter((project) =>
            project.name.toLowerCase().includes(search.toLowerCase())
        );
        const sortedProjects = filtered.sort((a, b) => a.name.localeCompare(b.name));
        setListprojects(sortedProjects);
    }, [projects, search]);

    useEffect(() => {
        console.log('Trying to get projects');

        const data = {
            userid: user.userid
        }

        axios.post(configData.CONTROLLERURL + configData.GETPROJECTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Projects data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                dispatch({
                    type: 'SET_PROJECTS',
                    projects: res.data
                });
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const addProject = () => {
        navigation('addproject');
    }

    const importProject = () => {
        const fileInput = document.getElementById('jsonFileInput');
        fileInput.click();
    }

    const backupProjects = async () => {
        console.log('Trying to backup projects');

        const zip = new JSZip();
        const today = new Date().toLocaleDateString('en-GB');
        const formattedDate = today.replace(/\//g, '_');
        const backupFileName = `backup_${formattedDate}.zip`;

        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];

            const data = {
                projectuserid: project.userid,
                projectid: project.projectid
            };

            try {
                const res = await axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": user.userid,
                        "usertoken": user.usertoken
                    }
                });

                console.log('Project data received');
                console.log(res.data);

                if (res.data instanceof Object) {
                    res.data.version = version;
                    const pdContent = JSON.stringify(res.data);
                    zip.file(`${project.projectid}.pd`, pdContent);
                }

                const currentProgress = `${i + 1}/${projects.length}`;
                setProgress(currentProgress);
            } catch (err) {
                console.error(`Error backing up project ${project.projectid}`, err);
            }
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, backupFileName);

        setProgress(null);
    };

    const handleFileUpload = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                try {
                    const jsonContent = JSON.parse(e.target.result);
                    jsonContent.imported = true;
                    dispatch({
                        type: 'SET_PROJECT',
                        project: jsonContent
                    });
                    navigation('home');
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };

            reader.readAsText(file);
        }
    };

    const selectProject = (project, index) => {
        console.log('Trying to get project')

        setLoading(index);

        const data = {
            projectuserid: project.userid,
            projectid: project.projectid
        }

        axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Project data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                res.data.size = project.size;
                dispatch({
                    type: 'SET_PROJECT',
                    project: res.data
                });
                navigation('home');
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(null);
        });
    }

    return (
        <div className='projects'>
            <div>
                <img className='projects_logo' src={projects_logo} alt="Logo" />
            </div>
            <div className='projects_header'>
                <div className='projects_headersection'><div className='projects_headertitle'>Projects ({listprojects.length})</div></div>
                {user.usertype <= 2 && <div className='projects_headersection'><button className='projects_addbutton' onClick={addProject}>Add Project</button></div>}
                {user.usertype <= 2 && <div className='projects_headersection'><button className='projects_removebutton' onClick={importProject}>Import Project</button></div>}
                {user.usertype <= 2 && <div className='projects_headersection'><button className='projects_backupbutton' onClick={() => backupProjects()}>Backup all data {progress}</button></div>}
                <input
                    type='file'
                    accept='.pd'
                    style={{ display: 'none' }}
                    id='jsonFileInput'
                    onChange={handleFileUpload}
                />
            </div>
            <div>
                <input
                    className="input"
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className='projects_projectscontainer'>
                <div className='projects_seperator' />
                {
                    listprojects.map((project, index) => (
                        <div key={'project' + index} className='projects_projectbutton'>
                            <div className='projects_projecttext' onClick={() => selectProject(project, index)}>{project.name} {loading === index && '(loading...)'}</div>
                            <div className='projects_projectsize'>{project.size} KB</div>
                        </div>
                    ))
                }
            </div>
            <div className='projects_versiontext'>
                Version {version}
            </div>
        </div>

    );
}

export default Projects;