import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import axios from 'axios';
import configData from './Config';

import Title from './Title.js';

import './Reconciliations.css';

function Reconciliations() {
    const [{ user, project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddReconciliation />)
    }

    const editButton = (reconciliation) => {
        setOption(<EditReconciliation reconciliation={reconciliation} />)
    }

    const removeButton = (reconciliation) => {
        setOption(<RemoveReconciliation reconciliation={reconciliation} />)
    }

    function AddReconciliation() {
        const [date, setDate] = useState(new Date());
        const [ts, setTs] = useState(0);
        const [selectedaccount, setSelectedaccount] = useState('');
        const [balance, setBalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setTs(Math.floor(new Date(date).getTime() / 1000));
        }, [date]);

        const addReconciliation = (ts, selectedaccount, balance) => {
            console.log('Trying to add reconciliation');

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                cashaccountid: selectedaccount,
                balance: balance
            }

            axios.post(configData.CONTROLLERURL + configData.ADDRECONCILIATION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add reconciliation data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_RECONCILIATION',
                            reconciliation: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Reconciliation</div>
                        <div className='row'>
                            <div className='rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='rowsection'>
                                <select className='modal_select' value={selectedaccount} onChange={(e) => setSelectedaccount(e.target.value)}>
                                    <option value="">Select an account</option>
                                    {project.cashaccounts.map((account, index) => (
                                        <option key={'account' + index} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='rowsection'>
                                <div className='modal_label'>Balance</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="0"
                                    value={balance}
                                    onChange={(e) => setBalance(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addReconciliation(ts, selectedaccount, balance)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditReconciliation({ reconciliation }) {
        const [id, setId] = useState('');

        const [date, setDate] = useState('');
        const [ts, setTs] = useState(0);
        const [selectedaccount, setSelectedaccount] = useState('');
        const [balance, setBalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reconciliation.id)
            setTs(reconciliation.ts);
            setDate(new Date(reconciliation.ts * 1000));
            setSelectedaccount(reconciliation.cashaccountid)
            setBalance(reconciliation.balance);
        }, [reconciliation]);

        useEffect(() => {
            setTs(Math.floor(new Date(date).getTime() / 1000));
        }, [date]);

        const updateReconciliation = (id, ts, selectedaccount, balance) => {
            console.log('Trying to update reconciliation');

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                cashaccountid: selectedaccount,
                balance: balance
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATERECONCILIATION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update reconciliation data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_RECONCILIATION',
                            reconciliation: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Reconciliation</div>
                        <div className='row'>
                            <div className='rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='rowsection'>
                                <select className='modal_select' value={selectedaccount} onChange={(e) => setSelectedaccount(e.target.value)}>
                                    <option value="">Select an account</option>
                                    {project.cashaccounts.map((account, index) => (
                                        <option key={'account' + index} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='rowsection'>
                                <div className='modal_label'>Balance</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="0"
                                    value={balance}
                                    onChange={(e) => setBalance(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateReconciliation(id, ts, selectedaccount, balance)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(reconciliation)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveReconciliation({ reconciliation }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reconciliation.id)
        }, [reconciliation]);

        const removeReconciliation = (id) => {
            console.log('Trying to remove reconciliation');

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVERECONCILIATION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove reconciliation data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_RECONCILIATION',
                            reconciliationid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeReconciliation(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='reconciliations'>
            <Title text='Reconciliations' />

            <button className='button' onClick={addButton}>Add Reconciliation</button>

            <table className='table'>
                <thead><tr><th></th><th>Date</th><th>Cash Account</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        project.reconciliations.map((reconciliation, index) => {
                            let cashaccount = project.cashaccounts.find(account => account.id === reconciliation.cashaccountid)?.name || 'Account not found';
                            let date = moment.unix(reconciliation.ts).format('D/MM/YYYY');

                            return (
                                <tr key={'reconciliation' + index}>
                                    <td><div className='table_button' onClick={() => editButton(reconciliation)}>Edit</div></td>
                                    <td><div>{date}</div></td>
                                    <td><div>{cashaccount}</div></td>
                                    <td><div>{reconciliation.balance}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Reconciliations;