import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import moment from 'moment';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';
import '../Settings.css';

function Subaccounts() {
    const [{ user, project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddSubaccount />);
    }

    const editButton = (subaccount) => {
        setOption(<EditSubaccount subaccount={subaccount} />);
    }

    const removeButton = (subaccount) => {
        setOption(<RemoveSubaccount subaccount={subaccount} />);
    }

    function AddSubaccount() {
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addSubaccount = (name) => {
            console.log('Trying to add subaccount');

            if(!name){
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.subaccounts.some(subaccount => subaccount.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Subaccount with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDSUBACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add subaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.data;
                        dispatch({
                            type: 'ADD_SUBACCOUNT',
                            subaccount: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>New Subaccount</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addSubaccount(name)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditSubaccount({ subaccount }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(subaccount.id);
            setName(subaccount.name);
        }, [subaccount]);

        const updateSubaccount = (id, name) => {
            console.log('Trying to update subaccount');

            if(!name){
                setResult('Missing info');
                return;
            }
            
            const trimmedName = name.trim();

            const isNameDuplicate = project.subaccounts.some(subaccount => subaccount.name === trimmedName && subaccount.id !== id);
            if (isNameDuplicate) {
                setResult('Subaccount with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATESUBACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update subaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_SUBACCOUNT',
                            subaccount: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Subaccount</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateSubaccount(id, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(subaccount)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveSubaccount({ subaccount }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(subaccount.id);
            setName(subaccount.name);
        }, [subaccount]);

        const removeSubaccount = (id) => {
            console.log('Trying to remove subaccount');

            if (project.journalentries) {
                const hasJournalEntryData = project.journalentries.some((entry) => entry.subaccount === id);
                if (hasJournalEntryData) {
                    setResult('Project has journal entry data related to ' + name);
                    return;
                }
            }

            if (project.transactions) {
                const hasTransactionData = project.transactions.some((transaction) => transaction.subaccount === id);
                if (hasTransactionData) {
                    setResult('Project has transaction data related to ' + name);
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVESUBACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove subaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_SUBACCOUNT',
                            subaccountid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Remove Subaccount</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeSubaccount(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='settings_subaccounts'>
            <Title text='Subaccounts' />
            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>New Subaccount</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Name</th></tr></thead>
                <tbody>
                    {
                        project.subaccounts.map((subaccount, index) => (
                            <tr key={'subaccounts' + index}>
                                <td><div className='table_button' onClick={() => editButton(subaccount)}>Edit</div></td>
                                <td>{subaccount.name}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    )
}

export default Subaccounts;