import React, { useState, useEffect, useRef } from 'react';

import './select.css';

const ClientSelect = ({ clients, clientid, setClientid, width }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleSelect = (clientId) => {
        setClientid(clientId);
        setShowDropdown(false);
    };
    
    const filteredClients = clients
        .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        .filter(client =>
            client.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            client.id !== clientid
        );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (showDropdown && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showDropdown]);

    const clearSelection = () => {
        handleSelect(null);
        setShowDropdown(false);
        setClientid('');
    };

    return (
        <div className="inputgroup" ref={dropdownRef} style={{ width: width }}>
            <input
                type="text"
                className='inputgroup_input'
                placeholder="Select a client"
                value={clientid ? clients.find(client => client.id === clientid)?.name : ''}
                onClick={() => setShowDropdown(true)}
                readOnly
            />
            {clientid && (
                <button className="inputgroup_clearbutton" onClick={clearSelection}>
                    X
                </button>
            )}
            {showDropdown && (
                <div className="inputgroup_dropdown">
                    <input
                        type="text"
                        className="inputgroup_dropdowninput"
                        placeholder="Search clients"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ref={inputRef}
                    />
                    <ul className='inputgroup_dropdownul'>
                        {filteredClients.map((client, index) => (
                            <li
                                key={'client' + index}
                                className='inputgroup_dropdownli'
                                onClick={() => handleSelect(client.id)}
                            >
                                {client.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ClientSelect;