import React, { useState, useEffect } from 'react';

import './Title.css';

function Title({ text }) {

    return (
        <div className='title'>
            <div className='title_text'>{text}</div>
        </div>
    );
}

export default Title;