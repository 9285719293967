export function getPermission(projectuserid, users, userid, resource, option) {
    if (projectuserid === userid) {
        return true;
    }

    const user = users.find(user => user.id === userid);
    if (!user) {
        return false;
    }

    const permissions = user.permissions[resource];
    if (!permissions) {
        return false;
    }

    switch (option) {
        case 'open':
            return !!permissions.open;
        case 'add':
            return !!permissions.add;
        case 'update':
            return !!permissions.update;
        case 'remove':
            return !!permissions.remove;
        default:
            return false;
    }
}

export function getHomePermission(projectuserid, users, userId) {
    const menuItems = [
        'Summary',
        'Announcements',
        'Assets',
        'Budget',
        'Cash Accounts',
        'Reconciliations',
        'Clients',
        'Documents',
        'Energy Management Systems',
        'Inspections',
        'Interaccount Transfers',
        'Journal Entries',
        'Purchase Invoices',
        'Suppliers',
        'Contracts',
        'Tasks',
        'Transactions',
        'Quotations',
        'Offers',
        'Reports',
        'Settings'
    ];


    if (projectuserid === userId) {
        const allTruePermissions = {};
        menuItems.forEach(item => {
            allTruePermissions[item] = true;
        });
        return allTruePermissions;
    }

    const user = users.find(user => user.id === userId);

    if (!user || !user.permissions) {
        return {};
    }

    const homePermissions = {};

    menuItems.forEach(item => {
        if (user.permissions[item]) {
            homePermissions[item] = user.permissions[item].open;
        } else {
            homePermissions[item] = false;
        }
    });

    return homePermissions;
}

export function permissionsParser(permissions) {
    const notAllowedText = [];

    for (const [feature, actions] of Object.entries(permissions)) {
        const disallowed = Object.entries(actions)
            .filter(([action, allowed]) => !allowed)
            .map(([action]) => action);

        if (disallowed.length > 0) {
            notAllowedText.push(`${feature}: Not allowed ${disallowed.join(', ')}`);
        }
    }

    return notAllowedText.join('\n');
}