import React, { useRef, useEffect, useState } from 'react';
import { useStateValue } from './StateProvider';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import { getCashAccountSOA, getClientAccountSOA, getSupplierAccountSOA } from './Utils/statementaccount.js';

import './StatementAccount.css';

function StatementAccount({ type, account, setOption }) {
    const [{ project }, dispatch] = useStateValue();

    const printRef = useRef();

    const cancelButton = () => {
        setOption(null);
    };

    const handlePrint = async () => {
        const pdf = await ReactToPrint.render(printRef.current);
        window.open(pdf.toDataURL(), '_blank');
    };

    function CashAccountSOA() {
        const [statementData, setStatementData] = useState([]);

        useEffect(() => {
            const cashaccountsoa = getCashAccountSOA(account, project.transactions, project.interaccounttransfers, project.currencies, project.startdate);
            setStatementData(cashaccountsoa);
        }, [account, project.transactions, project.interaccounttransfers, project.currencies, project.startdate]);

        return (
            <>
                <table className='modal_table'>
                    <thead><tr><th>Date</th><th>Name</th><th>Debit</th><th>Credit</th><th>Balance</th></tr></thead>
                    <tbody>
                        {
                            statementData.map((entry, index) => {
                                return (
                                    <tr key={'entry' + index}>
                                        <td><div>{entry.date}</div></td>
                                        <td><div>{entry.name}</div></td>
                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }

    function ClientAccountSOA() {
        const [selectedSubaccountType, setSelectedSubaccountType] = useState(0);
        const [statementData, setStatementData] = useState([]);

        useEffect(() => {
            const clientaccountsoa = getClientAccountSOA(account, selectedSubaccountType, project.transactions, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);
            setStatementData(clientaccountsoa);
        }, [account, selectedSubaccountType, project.transactions, project.journalentries, project.exchangerates, project.currencies]);

        return (
            <>
                <div className='modal_row'>
                    <label>
                        <select className='modal_select' value={selectedSubaccountType} onChange={(e) => setSelectedSubaccountType(e.target.value)}>
                            <option value={0}>All</option>
                            {
                                project.subaccounts.map((subaccount) => (
                                    <option key={subaccount.id} value={subaccount.id}>
                                        {subaccount.name}
                                    </option>
                                ))
                            }
                        </select>
                    </label>
                </div>
                <table className='modal_table'>
                    <thead><tr><th>Date</th><th>Name</th><th>Debit</th><th>Credit</th><th>Balance</th></tr></thead>
                    <tbody>
                        {
                            statementData.map((entry, index) => {
                                return (
                                    <tr key={'entry' + index}>
                                        <td><div>{entry.date}</div></td>
                                        <td><div>{entry.name}</div></td>
                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }

    function SupplierAccountSOA() {
        const [selectedSubaccountType, setSelectedSubaccountType] = useState(0);
        const [statementData, setStatementData] = useState([]);

        useEffect(() => {
            const supplieraccountsoa = getSupplierAccountSOA(account, selectedSubaccountType, project.transactions, project.journalentries, project.purchaseinvoices, project.exchangerates, project.currencies, project.basecurrency);
            setStatementData(supplieraccountsoa);
        }, [account, selectedSubaccountType, project.transactions, project.journalentries, project.purchaseinvoices, project.exchangerates, project.currencies]);

        return (
            <>
                <div className='modal_row'>
                    <label>
                        <select className='modal_select' value={selectedSubaccountType} onChange={(e) => setSelectedSubaccountType(e.target.value)}>
                            <option value={0}>All</option>
                            {
                                project.subaccounts.map((subaccount) => (
                                    <option key={subaccount.id} value={subaccount.id}>
                                        {subaccount.name}
                                    </option>
                                ))
                            }
                        </select>
                    </label>
                </div>
                <table className='modal_table'>
                    <thead><tr><th>Date</th><th>Name</th><th>Debit</th><th>Credit</th><th>Balance</th></tr></thead>
                    <tbody>
                        {
                            statementData.map((entry, index) => {
                                return (
                                    <tr key={'entry' + index}>
                                        <td><div>{entry.date}</div></td>
                                        <td><div>{entry.name}</div></td>
                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <div className='modal'>
            <div className='modal_overlay'>
                <div className='modal_content' ref={printRef}>
                    <div className='modal_title'>Statement of Account</div>
                    <div className='modal_row'>
                        <div className='modal_label'>{account.name}</div>
                    </div>
                    {type === 'cashaccounts' && (
                        <CashAccountSOA />
                    )}
                    {type === 'clients' && (
                        <ClientAccountSOA />
                    )}
                    {type === 'suppliers' && (
                        <SupplierAccountSOA />
                    )}
                </div>
                <div className='modal_buttonscontainer'>
                    <ReactToPrint
                        trigger={() => (
                            <button className="modal_button" onClick={handlePrint}>Print</button>
                        )}
                        content={() => printRef.current}
                    />
                    <button className='modal_button' onClick={cancelButton}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default StatementAccount;