import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';

import axios from 'axios';
import configData from './Config';

import Title from './Title';

function UserManagement() {
    const [{ user, project }, dispatch] = useStateValue();

    const [users, setUsers] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        setUsers(project.users)
    }, [project]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddUser />)
    }

    const removeButton = (user) => {
        setOption(<RemoveUser user={user} />)
    }

    function AddUser() {
        const [email, setEmail] = useState('');

        const [result, setResult] = useState(null);

        const addUser = (email) => {
            console.log('Trying to add user');
            setResult(null);
    
            const userExists = project.users.some(user => user.email === email);
            if (userExists) {
              setResult('User email already exists');
              return;
            }
    
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                email: email
            }
    
            axios.post(configData.CONTROLLERURL + configData.ADDPROJECTUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PROJECT_USER',
                            user: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add User</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>User Email</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addUser(email)}>Save</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveUser({ user }) {
        const [id, setId] = useState('');
        
        const [result, setResult] = useState(null);
        
        useEffect(() => {
            setId(user.id)
        }, [user]);

        const removeUser = (id) => {
            console.log('Trying to remove user');
    
            setResult(null);
    
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }
    
            axios.post(configData.CONTROLLERURL + configData.REMOVEPROJECTUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_PROJECT_USER',
                            userid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeUser(id)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='users'>
            <Title text='Users' />

            <button className='button' onClick={addButton}>Add User</button>

            <table className='table'>
                <thead><tr><th></th><th>Id</th><th>Email</th></tr></thead>
                <tbody>
                    {
                        users.map((user, index) => {
                            return (
                                <tr key={'user' + index}>
                                    <td><div className='table_button' onClick={() => removeButton(user)}>Remove</div></td>
                                    <td>{user.id}</td>
                                    <td>{user.email}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default UserManagement;