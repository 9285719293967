export function getCashAccounts(cashaccounts, transactions, interaccounttransfers, currencies) { //20240108
    const data = [];

    cashaccounts.forEach((account) => {
        const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;

        let sum = parseFloat(account.openingbalance);

        transactions.forEach((transaction) => {
            if (transaction.currencyid === account.currencyid) {
                if (transaction.to === account.id) {
                    sum += parseFloat(transaction.amount);
                }
                if (transaction.from === account.id) {
                    sum -= parseFloat(transaction.amount);
                }
            }
        });

        interaccounttransfers.forEach((transaction) => {
            if (transaction.to === account.id) {
                sum += parseFloat(transaction.toamount);
            }
            if (transaction.from === account.id) {
                sum -= parseFloat(transaction.fromamount);
            }
        });

        data.push({
            id: account.id,
            name: account.name,
            currencyid: account.currencyid,
            currency: currencySymbol,
            openingbalance: account.openingbalance,
            balance: sum,
        });
    });

    return data;
}