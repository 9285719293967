import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title';

import './SalesInvoices.css';

function SalesInvoices() {
    const [{ user, project }, dispatch] = useStateValue();
    const [option, setOption] = useState(null);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddInvoice />);
    }

    const editButton = (invoice) => {
        setOption(<EditInvoice invoice={invoice} />);
    }

    const viewButton = (invoice) => {
        setOption(<ViewInvoice invoice={invoice} />);
    }

    const removeButton = (invoice) => {
        setOption(<RemoveInvoice invoice={invoice} />);
    }

    function NewItem({ item, index, handleItemChange, handleQtyChange, handleUnitPriceChange, handleRemoveItem }) {
        return (
            <tr key={'item' + index}>
                <td>
                    <select
                        className='modal_select'
                        value={item.itemid}
                        onChange={(e) => handleItemChange(e, index)}
                    >
                        <option value="">Select Item</option>
                        {project.inventory.map((inventoryItem, i) => (
                            <option key={'inventoryItem' + i} value={inventoryItem.id}>
                                {inventoryItem.name}
                            </option>
                        ))}
                    </select>
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        placeholder="Qty"
                        value={item.itemquantity}
                        onChange={(e) => handleQtyChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        placeholder="Unit Price"
                        value={item.itemprice}
                        onChange={(e) => handleUnitPriceChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveItem(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddInvoice() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [clients, setClients] = useState([]);
        const [selectedclient, setSelectedclient] = useState('');

        const [items, setItems] = useState([{}]);
        const [name, setName] = useState('');
        const [selectedcurrency, setSelectedCurrency] = useState('');
        
        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setClients(project.clients)
        }, []);

        const handleAddItem = () => {
            setItems([...items, {}])
        };

        const handleRemoveItem = () => {
            if (items.length > 1) {
                const updatedItems = items.slice(0, items.length - 1);
                setItems(updatedItems);
            }
        };

        const handleItemChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemid = selectedItem;
            setItems(updatedItems);
        };

        const handleQtyChange = (event, index) => {
            const selectedQty = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedQty;
            setItems(updatedItems);
        };

        const handleUnitPriceChange = (event, index) => {
            const selectedUnitPrice = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemprice = selectedUnitPrice;
            setItems(updatedItems);
        };

        const handleCurrencyChange = (e) => {
            setSelectedCurrency(e.target.value);
        };

        const amount = isNaN(items.reduce((sum, item) => sum + ((item.itemprice || 0) * item.itemquantity), 0)) ? 0 : items.reduce((sum, item) => sum + ((item.itemprice || 0) * item.itemquantity), 0)

        const addInvoice = (ts, clientid, currencyid, name, items, amount) => {
            console.log('Trying to add sales invoice');

            if (!clientid || !currencyid || !name) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                userid: user.userid,
                projectid: project.id,
                ts: ts,
                clientid: clientid,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }
    
            axios.post(configData.CONTROLLERURL + configData.ADDSALESINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add sales invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_SALESINVOICE',
                            salesinvoice: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Sales Invoice</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_label'>Currency</div>
                        <select className='modal_select' value={selectedcurrency} onChange={handleCurrencyChange}>
                            <option value="">Select a currency</option>
                            {project.currencies.map((currency, index) => (
                                <option key={'currency' + index} className='addinvoice_selectoption' value={currency.id}>
                                    {currency.symbol}
                                </option>
                            ))}
                        </select>
                        <div className='modal_label'>Client</div>
                        <select
                            className='modal_select'
                            value={selectedclient}
                            onChange={(e) => setSelectedclient(e.target.value)}
                        >
                            <option value="">Select client</option>
                            {clients.map((client, index) => (
                                <option key={'client' + index} value={client.id}>
                                    {client.name}
                                </option>
                            ))}
                        </select>
                        <div className='modal_label'>Description</div>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Qty</th><th>Unit price</th><th></th></tr></thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <NewItem
                                        key={'item' + index}
                                        item={item}
                                        index={index}
                                        handleItemChange={handleItemChange}
                                        handleQtyChange={handleQtyChange}
                                        handleUnitPriceChange={handleUnitPriceChange}
                                        handleRemoveItem={handleRemoveItem}
                                    />
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === selectedcurrency, 10)?.symbol || ''} {amount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <div className="modal_button" onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addInvoice(timestamp, selectedclient, selectedcurrency, name, items, amount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditInvoice({ invoice }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [clients, setClients] = useState([]);
        const [selectedclient, setSelectedclient] = useState('');

        const [items, setItems] = useState([{}]);
        const [name, setName] = useState('');
        const [selectedcurrency, setSelectedCurrency] = useState('');
        
        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(invoice.id);
            setSelecteddate(new Date(invoice.ts * 1000));
            setClients(project.clients);
            setSelectedclient(invoice.clientid);
            setItems(invoice.items);
            setName(invoice.name);
            setSelectedCurrency(invoice.currencyid);
        }, [invoice]);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddItem = () => {
            setItems([...items, {}])
        };

        const handleRemoveItem = () => {
            if (items.length > 1) {
                const updatedItems = items.slice(0, items.length - 1);
                setItems(updatedItems);
            }
        };

        const handleItemChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemid = selectedItem;
            setItems(updatedItems);
        };

        const handleQtyChange = (event, index) => {
            const selectedQty = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedQty;
            setItems(updatedItems);
        };

        const handleUnitPriceChange = (event, index) => {
            const selectedUnitPrice = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemprice = selectedUnitPrice;
            setItems(updatedItems);
        };

        const handleCurrencyChange = (e) => {
            setSelectedCurrency(e.target.value);
        };

        const amount = isNaN(items.reduce((sum, item) => sum + ((item.itemprice || 0) * item.itemquantity), 0)) ? 0 : items.reduce((sum, item) => sum + ((item.itemprice || 0) * item.itemquantity), 0)

        const updateInvoice = (id, timestamp, selectedclient, currencyid, name, items, amount) => {
            console.log('Trying to update sales invoice');
    
            if (!selectedclient || !currencyid || !name) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                userid: user.userid,
                projectid: project.id,
                id: id,
                ts: timestamp,
                clientid: selectedclient,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }
    
            axios.post(configData.CONTROLLERURL + configData.UPDATESALESINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update sales invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_SALESINVOICE',
                            salesinvoice: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Sales Invoice</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_label'>Currency</div>
                        <select className='modal_select' value={selectedcurrency} onChange={handleCurrencyChange}>
                            <option value="">Select a currency</option>
                            {project.currencies.map((currency, index) => (
                                <option key={'currency' + index} value={currency.id}>
                                    {currency.symbol}
                                </option>
                            ))}
                        </select>
                        <div className='modal_label'>Client</div>
                        <select
                            className='modal_select'
                            value={selectedclient}
                            onChange={(e) => setSelectedclient(e.target.value)}
                        >
                            <option value="">Select client</option>
                            {clients.map((client, index) => (
                                <option key={'client' + index} value={client.id}>
                                    {client.name}
                                </option>
                            ))}
                        </select>
                        <div className='modal_label'>Description</div>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Qty</th><th>Unit price</th><th></th></tr></thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <NewItem
                                        key={'item' + index}
                                        item={item}
                                        index={index}
                                        handleItemChange={handleItemChange}
                                        handleQtyChange={handleQtyChange}
                                        handleUnitPriceChange={handleUnitPriceChange}
                                        handleRemoveItem={handleRemoveItem}
                                    />
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === selectedcurrency, 10)?.symbol || ''} {amount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <div className="modal_button" onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateInvoice(id, timestamp, selectedclient, selectedcurrency, name, items, amount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(invoice)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewInvoice({ invoice }) {
        const [timestamp, setTimstamp] = useState(0);

        const [items, setItems] = useState([]);
        const [name, setName] = useState('');
        const [clientid, setClientid] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setTimstamp(invoice.ts);
            setItems(invoice.items);
            setName(invoice.name);
            setClientid(invoice.clientid)
            setCurrencyid(invoice.currencyid)
        }, [invoice]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content' ref={printRef}>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_title'>Sales Invoice</div>
                                <div className='modal_date'>{moment.unix(timestamp).format('dddd Do MMMM YYYY')}</div>
                            </div>
                            <div className='modal_rowsection'></div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Client</b> {project.clients.find(item => item.id === clientid, 10)?.name || 'Client not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_rowsection'>{name}</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Price</th><th>Net</th></tr></thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <tr key={'item' + index}>
                                            <td>{project.inventory.find(inv => inv.id === item.itemid)?.name || 'Item not found'}</td>
                                            <td>{item.itemquantity}</td>
                                            <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemprice}</td>
                                            <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemquantity * item.itemprice}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Balance due</td>
                                    <td></td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {items.reduce((sum, item) => sum + ((item.itemprice || 0) * item.itemquantity), 0)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveInvoice({ invoice }) {
        const [id, setId] = useState('');
        
        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(invoice.id);
        }, [invoice]);

        const removeInvoice = (id) => {
            console.log('Trying to remove sales invoice');
    
            setLoading(true);

            const data = {
                userid: user.userid,
                projectid: project.id,
                id: id
            }
    
            axios.post(configData.CONTROLLERURL + configData.REMOVESALESINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove sales invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_SALESINVOICE',
                            salesinvoiceid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeInvoice(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='salesinvoices'>
            <Title text='Sales Invoices' />
            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Sales Invoice</button>
            </div>
            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Client</th><th>Name</th><th>Total</th></tr></thead>
                <tbody>
                    {
                        project.salesinvoices.map((invoice, index) => (
                            <tr key={'invoice' + index}>
                                <td><div className='table_button' onClick={() => editButton(invoice)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(invoice)}>View</div></td>
                                <td>{moment.unix(invoice.ts).format('D/MMM/YYYY')}</td>
                                <td>{project.clients.find(item => item.id === invoice.clientid, 10)?.name || 'Client not found'}</td>
                                <td>{invoice.name}</td>
                                <td>{project.currencies.find(item => item.id === invoice.currencyid, 10)?.symbol || 'Currency not found'} {invoice.amount}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default SalesInvoices;