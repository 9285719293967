import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useStateValue } from './StateProvider';

import axios from 'axios';
import configData from './Config';

import UpdatePage from "./UpdatePage";
import AuthScreen from "./AuthScreen";
import Projects from "./Projects";
import Home from "./Home";
import AddProject from "./AddProject";

function App() {
    const [{ user }, dispatch] = useStateValue();

    const [versionlock, setVersionlock] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('/manifest.json');
            const manifestData = await response.json();

            dispatch({
                type: 'SET_VERSION',
                version: manifestData.version
            });

            const checkVersions = async () => {
                axios.post(configData.CONTROLLERURL + configData.GETVERSION, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8"
                    },
                }).then((res) => {
                    if (res.data === manifestData.version) {
                        setVersionlock(false);
                    } else {
                        setVersionlock(true);
                    }
                }).catch(() => {
                    console.log('Not connected')
                });
            };

            await checkVersions();

            const intervalId = setInterval(checkVersions, 60000);

            return () => clearInterval(intervalId);
        };

        fetchData();

    }, [dispatch]);

    useEffect(() => {
        getLocalUser().then(value => {
            if (value.user != null) {
                validateUser(value.user.userid, value.user.usertoken)
            }
            else {
            }
        })
    }, []);

    async function getLocalUser() {
        const user = await localStorage.getItem('user');
        return {
            user: user != null ? JSON.parse(user) : null
        }
    }

    function validateUser(userid, usertoken) {

        const data = {
            userid: userid,
            usertoken: usertoken
        }

        axios.post(configData.CONTROLLERURL + configData.VALIDATEUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'ADD_USER',
                        user: {
                            userid: userid,
                            usertoken: usertoken,
                            useremail: res.data.data.useremail,
                            username: res.data.data.username,
                            usertype: res.data.data.usertype,
                        }
                    });
                }
                else {
                }
            }
            else {
            }
        }).catch(() => {

        })
    }

    return (
        versionlock ?
            <BrowserRouter>
                <Routes>
                    <Route index element={<UpdatePage />} />
                </Routes>
            </BrowserRouter>
            :
            user.userid ?
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Projects />} />
                            <Route path='home' element={<Home />} />
                            <Route path='addproject' element={<AddProject />} />
                            <Route path='authscreen' element={<AuthScreen />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                :
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<AuthScreen />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
    );
}

export default App;