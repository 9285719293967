export function findNearestExchangeRate(exchangerates, nearestts, quotecurrency, basecurrency) { //20240108
    let nearestRate = null;

    for (let i = 0; i < exchangerates.length; i++) {
        const entry = exchangerates[i];
        if ((entry.fromcurrencyid == basecurrency && entry.tocurrencyid == quotecurrency) ||
            (entry.fromcurrencyid == quotecurrency && entry.tocurrencyid == basecurrency)) {
            if (!nearestRate || Math.abs(entry.ts - nearestts) < Math.abs(nearestRate.ts - nearestts)) {
                nearestRate = entry;
            }
        }
    }

    if (nearestRate) {
        if (quotecurrency !== nearestRate.fromcurrencyid) {
            nearestRate = {
                ts: nearestRate.ts,
                fromcurrencyid: nearestRate.tocurrencyid,
                tocurrencyid: nearestRate.fromcurrencyid,
                toamount: 1 / nearestRate.toamount,
            };
        }

        return { nearestexchangerateentry: nearestRate, exchangerate: parseFloat(nearestRate.toamount) };
    }

    return { nearestexchangerateentry: {}, exchangerate: 1 };
}