export function numberToWords(amount) { //20240108
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const hundred = 'Hundred';

    const convert = (num) => {
        if (num === 0) return '';
        else if (num < 10) return units[num];
        else if (num < 20) return num === 10 ? 'Ten' : teens[num - 11];
        else return `${tens[Math.floor(num / 10)]} ${units[num % 10]}`;
    };

    const convertChunk = (num) => {
        if (num === 0) return '';
        else if (num < 100) return convert(num);
        else return `${units[Math.floor(num / 100)]} ${hundred} ${convert(num % 100)}`;
    };

    if (amount === 0) return 'Zero';

    const chunks = amount.toString().match(/.{1,3}/g).map(Number).reverse();
    const words = chunks.map((num, index) => convertChunk(num) + (num ? ` ${['', 'Thousand', 'Million', 'Billion'][index]}` : ''));

    return words.filter(Boolean).reverse().join(' ');
}