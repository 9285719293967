import React, { useState, useRef, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import { useNavigate } from 'react-router-dom';
import Admin from './Admin/Admin.js';

import Profile from './Settings/Profile';

import header_logo from './assets/header_logo.png';
import header_notifications from './assets/header_notifications.png';
import header_user from './assets/header_user.png';

import './Header.css';

function Header({ setOption }) {
    const [{ user, notifications }, dispatch] = useStateValue();

    const navigation = useNavigate();
    const settingsRef = useRef(null);
    const [suboption, setSuboption] = useState(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (settingsRef.current && !settingsRef.current.contains(event.target)) {
                setSuboption(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogo = () => {
        navigation('/');
    };

    const handleNotifications = () => {
        if (!suboption) setSuboption(<Notifications />);
        else setSuboption(null);
    };

    const handleSettings = () => {
        if (!suboption) setSuboption(<Settings />);
        else setSuboption(null);
    };

    const handleAdmin = () => {
        setOption(<Admin />);
        setSuboption(null);
    };

    const handleProfile = () => {
        setOption(<Profile />);
        setSuboption(null);
    };

    async function handleLogout() {
        setSuboption(null);
        dispatch({
            type: "REMOVE_USER"
        });
        await localStorage.removeItem('user');
        navigation('/');
    }

    function Notifications() {
        return (
            <div className='header_dropdown'>
                <div className='header_dropdownitem'>No notifications</div>
            </div>
        );
    }

    function Settings() {
        return (
            <div className='header_dropdown'>
                {user.usertype == 1 && <div className='header_dropdownitem' onClick={handleAdmin}>Admin</div>}
                <div className='header_dropdownitem' onClick={handleProfile}>Profile</div>
                <div className='header_dropdownitem' onClick={handleLogout}>Logout</div>
            </div>
        );
    }

    return (
        <div className='header'>
            <div className='header_content'>
                <div className='header_section'>
                    <div className='header_logo'>
                        <div onClick={handleLogo}>
                            <img className='header_logoimage' src={header_logo} alt="Logo" />
                        </div>
                    </div>
                </div>
                <div className='header_section'>
                    <div className='header_notification' onClick={handleNotifications}>
                        <img className='header_notificationsimage' src={header_notifications} alt="Notifications" />
                    </div>
                    <div className='header_notification' onClick={handleSettings}>
                        <img className='header_settingsimage' src={header_user} alt="Settings" />
                    </div>
                </div>
            </div>
            <div ref={settingsRef}>{suboption}</div>
        </div>
    );
}

export default Header;