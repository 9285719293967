import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title';
import { numberToWords } from './Utils/numbertowords.js';

import './Transactions.css';

function Transactions() {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredtransactions, setFilteredTransactions] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.transactions.filter((transaction) =>
            transaction.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredTransactions(filtered)
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addReceiptButton = () => {
        setOption(<AddReceipt />);
    }

    const addPaymentButton = () => {
        setOption(<AddPayment />);
    }

    const editPaymentButton = (transaction) => {
        setOption(<EditPayment transaction={transaction} />);
    }

    const editReceiptButton = (transaction) => {
        setOption(<EditReceipt transaction={transaction} />);
    }

    const viewReceiptButton = (transaction) => {
        setOption(<ViewReceipt transaction={transaction} />);
    }

    const viewPaymentButton = (transaction) => {
        setOption(<ViewPayment transaction={transaction} />);
    }

    const removeTransactionButton = (transaction) => {
        setOption(<RemoveTransaction transaction={transaction} />);
    }

    function AddReceipt() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const addReceipt = (ts, name, from, subaccount, to, amount, currencyid) => {
            console.log('Trying to add transaction');
            setResult(null);

            if (!name || !selectedclient || !subaccount || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccount: subaccount,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }
            console.log(data)
            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Receipt</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedclient} onChange={(e) => setSelectedclient(e.target.value)}>
                                <option value="">Select a client</option>
                                {project.clients.map((client, index) => (
                                    <option key={'client' + index} value={client.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedSubaccount(e.target.value)}>
                                <option value="">Select a subaccount</option>
                                {project.subaccounts.map((subaccount, index) => (
                                    <option key={'subaccount' + index} value={subaccount.id}>
                                        {subaccount.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedaccount} onChange={(e) => setSelectedaccount(e.target.value)}>
                                <option value="">Select an account</option>
                                {project.cashaccounts.map((account, index) => (
                                    <option key={'account' + index} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={(e) => setCurrencyid(e.target.value)}>
                                <option value="">Select a currency</option>
                                {project.currencies.map((currency, index) => (
                                    <option key={'currency' + index} value={currency.id}>
                                        {currency.symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addReceipt(timestamp, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddPayment() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedaccount, setSelectedaccount] = useState('');
        const [selectedexpense, setSelectedexpense] = useState('');
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const addPayment = (ts, name, from, to, supplier, subaccount, amount, currencyid) => {
            console.log('Trying to add transaction');
            setResult(null);

            if (!name || !selectedaccount || !selectedexpense || !selectedsupplier || !subaccount || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                to: to,
                beneficiaryid: supplier,
                subaccount: subaccount,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Payment</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedaccount} onChange={(e) => setSelectedaccount(e.target.value)}>
                                <option value="">Select an account</option>
                                {project.cashaccounts.map((account, index) => (
                                    <option key={'account' + index} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedexpense} onChange={(e) => setSelectedexpense(e.target.value)}>
                                <option value="">Select an expense</option>
                                {project.expenseaccounts.map((account, index) => (
                                    <option key={'account' + index} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedsupplier} onChange={(e) => setSelectedsupplier(e.target.value)}>
                                <option value="">Select a supplier</option>
                                {project.suppliers.map((supplier, index) => (
                                    <option key={'supplier' + index} value={supplier.id}>
                                        {supplier.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedSubaccount(e.target.value)}>
                                <option value="">Select a subaccount</option>
                                {project.subaccounts.map((subaccount, index) => (
                                    <option key={'subaccount' + index} value={subaccount.id}>
                                        {subaccount.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={(e) => setCurrencyid(e.target.value)}>
                                <option value="">Select a currency</option>
                                {project.currencies.map((currency, index) => (
                                    <option key={'currency' + index} value={currency.id}>
                                        {currency.symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addPayment(timestamp, name, selectedaccount, selectedexpense, selectedsupplier, selectedsubaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditReceipt({ transaction }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [ts, setTs] = useState(0);

        const [name, setName] = useState('');

        const [clients, setClients] = useState([]);
        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [cashaccounts, setCashaccounts] = useState([]);
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTs(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transaction.id);
            setSelecteddate(new Date(transaction.ts * 1000));
            setName(transaction.name);
            setClients(project.clients);
            setSelectedclient(transaction.from);
            setSelectedSubaccount(transaction.subaccount);
            setCashaccounts(project.cashaccounts);
            setSelectedaccount(transaction.to);
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid);
        }, [transaction]);

        const updateReceipt = (id, ts, name, from, subaccount, to, amount, currencyid) => {
            console.log('Trying to update transaction');
            setResult(null);

            if (!name || !selectedclient || !subaccount || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccount: subaccount,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Transaction</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedclient} onChange={(e) => setSelectedclient(e.target.value)}>
                                {clients.map((client, index) => (
                                    <option key={'client' + index} value={client.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedSubaccount(e.target.value)}>
                                <option value="">Select a subaccount</option>
                                {project.subaccounts.map((subaccount, index) => (
                                    <option key={'subaccount' + index} value={subaccount.id}>
                                        {subaccount.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedaccount} onChange={(e) => setSelectedaccount(e.target.value)}>
                                {cashaccounts.map((account, index) => (
                                    <option key={'account' + index} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={(e) => setCurrencyid(e.target.value)}>
                                <option value="">Select a currency</option>
                                {project.currencies.map((currency, index) => (
                                    <option key={'currency' + index} value={currency.id}>
                                        {currency.symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateReceipt(id, ts, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeTransactionButton(transaction)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPayment({ transaction }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [ts, setTs] = useState(0);

        const [name, setName] = useState('');

        const [cashaccounts, setCashaccounts] = useState([]);
        const [selectedaccount, setSelectedaccount] = useState('');
        const [expenseaccounts, setExpenseaccounts] = useState([]);
        const [selectedexpense, setSelectedexpense] = useState('');
        const [suppliers, setSuppliers] = useState([]);
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTs(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transaction.id);
            setSelecteddate(new Date(transaction.ts * 1000));
            setName(transaction.name);
            setCashaccounts(project.cashaccounts);
            setSelectedaccount(transaction.from)
            setExpenseaccounts(project.expenseaccounts);
            setSelectedSubaccount(transaction.subaccount);
            setSelectedexpense(transaction.to);
            setSuppliers(project.suppliers);
            setSelectedsupplier(transaction.beneficiaryid)
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid);
        }, [transaction]);

        const updatePayment = (id, ts, name, from, to, supplier, subaccount, amount, currencyid) => {
            console.log('Trying to update transaction');

            if (!name || !from || !to || !supplier || !subaccount || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedName,
                from: from,
                to: to,
                beneficiaryid: supplier,
                subaccount: subaccount,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Transaction</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedaccount} onChange={(e) => setSelectedaccount(e.target.value)}>
                                {cashaccounts.map((account, index) => (
                                    <option key={'account' + index} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedexpense} onChange={(e) => setSelectedexpense(e.target.value)}>
                                <option value="">Select an expense</option>
                                {expenseaccounts.map((account, index) => (
                                    <option key={'account' + index} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedsupplier} onChange={(e) => setSelectedsupplier(e.target.value)}>
                                <option value="">Select a supplier</option>
                                {suppliers.map((supplier, index) => (
                                    <option key={'supplier' + index} value={supplier.id}>
                                        {supplier.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedSubaccount(e.target.value)}>
                                <option value="">Select a subaccount</option>
                                {project.subaccounts.map((subaccount, index) => (
                                    <option key={'subaccount' + index} value={subaccount.id}>
                                        {subaccount.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={(e) => setCurrencyid(e.target.value)}>
                                <option value="">Select a currency</option>
                                {project.currencies.map((currency, index) => (
                                    <option key={'currency' + index} value={currency.id}>
                                        {currency.symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updatePayment(id, ts, name, selectedaccount, selectedexpense, selectedsupplier, selectedsubaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeTransactionButton(transaction)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewReceipt = ({ transaction }) => {
        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content' ref={printRef}>
                        <div className='modal_head'>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_title'>RECEIPT</div>
                                    <div className='modal_subtitle'>No. <b>{transaction.id}</b></div>
                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_subtitle'>{project.projectdetails}</div>
                                </div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_center'>
                                    {project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}
                                </div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>For</b> {transaction.name}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_signature'></div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    };

    function ViewPayment({ transaction }) {
        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content' ref={printRef}>
                        <div className='modal_head'>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_title'>PAYMENT</div>
                                    <div className='modal_subtitle'>No. <b>{transaction.id}</b></div>
                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_subtitle'>{project.projectdetails}</div>
                                </div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_center'>
                                    {project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}
                                </div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Payment to</b> {project.expenseaccounts.find(item => item.id === transaction.to)?.name || ''}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Beneficiary</b> {project.suppliers.find(item => item.id === transaction.beneficiaryid)?.name || ''}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>For</b> {transaction.name}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }

    function RemoveTransaction({ transaction }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(transaction.id);
        }, [transaction]);

        const removeTransaction = (id) => {
            console.log('Trying to remove transaction');
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            setLoading(true);

            axios.post(configData.CONTROLLERURL + configData.REMOVETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_TRANSACTION',
                            id: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_title'>Remove Transaction</div>
                    <div className='modal_result'>{result && result}</div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => removeTransaction(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='transactions'>
            <Title text='Transactions' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addReceiptButton}>Receipt</button>
                    <button className='button' onClick={addPaymentButton}>Payment</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Type</th><th>From</th><th>To</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredtransactions.sort((a, b) => b.ts - a.ts).map((transaction, index) => {
                            if (transaction.type === 'receipt') {
                                return (
                                    <tr key={'transaction' + index} className='transactions_receipt'>
                                        <td><div className='table_button' onClick={() => editReceiptButton(transaction)}>Edit</div></td>
                                        <td><div className='table_button' onClick={() => viewReceiptButton(transaction)}>View</div></td>
                                        <td>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</td>
                                        <td>{transaction.type}</td>
                                        <td>
                                            {project.clients.find(item => item.id === transaction.from)?.name || ''}
                                        </td>
                                        <td>
                                            {project.cashaccounts.find(item => item.id === transaction.to)?.name || ''}
                                        </td>
                                        <td>{transaction.name}</td>
                                        <td>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {transaction.amount}</td>
                                    </tr>
                                )
                            }
                            if (transaction.type === 'payment') {
                                return (
                                    <tr key={'transaction' + index} className='transactions_payment'>
                                        <td><div className='table_button' onClick={() => editPaymentButton(transaction)}>Edit</div></td>
                                        <td><div className='table_button' onClick={() => viewPaymentButton(transaction)}>View</div></td>
                                        <td>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</td>
                                        <td>{transaction.type}</td>
                                        <td>
                                            {project.cashaccounts.find(item => item.id === transaction.from)?.name || ''}
                                        </td>
                                        <td>
                                            {project.expenseaccounts.find(account => account.id === transaction.to)?.name || ''}
                                        </td>
                                        <td>{transaction.name}</td>
                                        <td>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Transactions;