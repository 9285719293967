import { findNearestExchangeRate } from './currency.js';
import moment from 'moment';

export function getCashAccountSOA(account, transactions, interaccounttransfers, currencies, startdate) {
    const dataArray = [];
    const currency = currencies.find((currency) => currency.id === account.currencyid)?.symbol || '';

    let openingBalance = parseFloat(account.openingbalance);

    if (openingBalance !== 0) {
        const date = moment.unix(startdate).format('D/MMM/YYYY');
        const isDebit = openingBalance < 0;

        dataArray.push({
            ts: startdate,
            date: date,
            name: 'Opening Balance',
            debitcurrency: currency,
            debitamount: isDebit ? Math.abs(openingBalance) : 0,
            creditcurrency: currency,
            creditamount: isDebit ? 0 : openingBalance
        });
    }

    transactions.forEach((transaction) => {
        let debit = 0;
        let credit = 0;

        if (transaction.currencyid === account.currencyid) {
            if (transaction.from === account.id) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                debit = parseFloat(transaction.amount);
                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    name: transaction.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
            }
            else if (transaction.to === account.id) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                credit = parseFloat(transaction.amount);
                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    name: transaction.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
            }
        }
    });

    interaccounttransfers.forEach((transaction) => {
        const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
        let debit = 0;
        let credit = 0;

        if (transaction.from === account.id) {
            debit = parseFloat(transaction.fromamount);
        }
        else if (transaction.to === account.id) {
            credit = parseFloat(transaction.toamount);
        }

        dataArray.push({
            ts: transaction.ts,
            date: date,
            name: transaction.name,
            debitcurrency: currency,
            debitamount: debit,
            creditcurrency: currency,
            creditamount: credit
        });
    });

    dataArray.sort((a, b) => a.ts - b.ts);

    let balance = 0;
    dataArray.forEach((entry) => {
        entry.balancecurrency = entry.creditcurrency;
        entry.balanceamount = balance + entry.creditamount - entry.debitamount;
        balance = entry.balanceamount;
    });

    dataArray.sort((a, b) => b.ts - a.ts);

    return dataArray;
}

export function getClientAccountSOA(account, subaccount, transactions, journalentries, exchangerates, currencies, basecurrency) {
    const dataArray = [];
    const balancecurrencyid = account.currencyid;
    const balancecurrencysymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;

    transactions.forEach((transaction) => {
        if (transaction.from === account.id) {
            if (subaccount == 0 || transaction.subaccount === subaccount) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                const debit = 0;
                const credit = parseFloat(transaction.amount);

                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    name: transaction.name,
                    debitcurrencyid: transaction.currencyid,
                    debitamount: debit,
                    creditcurrencyid: transaction.currencyid,
                    creditamount: credit
                });
            }
        }
    });

    journalentries.forEach((entry) => {
        if (subaccount == 0 || entry.subaccount === subaccount) {
            entry.rows.forEach((row) => {
                if (row.accountid === account.id) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    const debit = parseFloat(row.debit);
                    const credit = parseFloat(row.credit);

                    dataArray.push({
                        ts: entry.ts,
                        date: date,
                        name: row.name ? row.name : entry.name,
                        debitcurrencyid: entry.currencyid,
                        debitamount: debit,
                        creditcurrencyid: entry.currencyid,
                        creditamount: credit
                    });
                }
            });
        }
    });

    dataArray.sort((a, b) => a.ts - b.ts);

    let balance = 0;
    dataArray.forEach((entry) => {
        if (entry.debitcurrencyid !== balancecurrencyid) {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.debitcurrencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            entry.balanceamount = balance + parseFloat(entry.debitamount * exchangerate) - parseFloat(entry.creditamount * exchangerate);
        }
        else {
            entry.balanceamount = balance + entry.debitamount - entry.creditamount;
        }

        entry.debitcurrency = currencies.find((currency) => currency.id === entry.debitcurrencyid)?.symbol;
        entry.creditcurrency = currencies.find((currency) => currency.id === entry.creditcurrencyid)?.symbol;
        entry.balancecurrency = balancecurrencysymbol;
        balance = entry.balanceamount;
    });

    dataArray.sort((a, b) => b.ts - a.ts);

    return dataArray;
}

export function getSupplierAccountSOA(account, subaccount, transactions, journalentries, purchaseinvoices, exchangerates, currencies, basecurrency) {
    const dataArray = [];
    const currency = currencies.find((currency) => currency.id === account.currencyid)?.symbol || '';

    transactions.forEach((transaction) => {
        if (transaction.beneficiaryid === account.id) {
            if (subaccount == 0 || transaction.subaccount === subaccount) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                let debit = 0;
                let credit = 0;

                if (transaction.currencyid === account.currencyid) {
                    credit = parseFloat(transaction.amount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    credit = parseFloat(transaction.amount) * exchangerate;
                }

                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    name: transaction.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
            }
        }
    });

    journalentries.forEach((entry) => {
        entry.rows.forEach((row) => {
            if (row.accountid === account.id) {
                if (subaccount == 0 || entry.subaccount === subaccount) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    let debit = 0;
                    let credit = 0;
                    if (entry.currencyid === account.currencyid) {
                        debit = parseFloat(row.debit);
                        credit = parseFloat(row.credit);
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, row.currencyid, basecurrency)
                        const exchangerate = NearestExchangeRate.exchangerate;
                        debit = parseFloat(row.debit) * exchangerate;
                        credit = parseFloat(row.credit) * exchangerate;
                    }

                    dataArray.push({
                        ts: entry.ts,
                        date: date,
                        name: row.name ? row.name : entry.name,
                        debitcurrency: currency,
                        debitamount: debit,
                        creditcurrency: currency,
                        creditamount: credit
                    });
                }
            }
        });
    });

    purchaseinvoices.forEach((invoice) => {
        if (invoice.supplierid === account.id) {
            if (subaccount == 0 || invoice.subaccount === subaccount) {
                const date = moment.unix(invoice.ts).format('D/MMM/YYYY');
                let debit = 0;
                let credit = 0;

                if (invoice.currencyid === account.currencyid) {
                    debit = parseFloat(invoice.amount)
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, invoice.ts, invoice.currencyid, basecurrency)
                    const exchangerate = NearestExchangeRate.exchangerate;
                    debit = parseFloat(invoice.amount) * exchangerate
                }

                dataArray.push({
                    ts: invoice.ts,
                    date: date,
                    name: 'Purchase Invoice',
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
            }
        }
    });

    dataArray.sort((a, b) => a.ts - b.ts);

    let balance = 0;
    dataArray.forEach((entry) => {
        entry.balancecurrency = entry.creditcurrency;
        entry.balanceamount = balance + entry.debitamount - entry.creditamount;
        balance = entry.balanceamount;
    });

    dataArray.sort((a, b) => b.ts - a.ts);

    return dataArray;
}