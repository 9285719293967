import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';

import Title from './Title';
import StatementAccount from './StatementAccount';
import { getClientAccounts } from './Utils/clients.js';

import './Clients.css';

function Clients() {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredclients, setFilteredclients] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    const clientsdata = getClientAccounts(filteredclients, project.transactions, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.clients.filter((client) =>
            client.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredclients(filtered)
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddClient />);
    }

    const editButton = (client) => {
        setOption(<EditClient client={client} />);
    }

    const removeButton = (client) => {
        setOption(<RemoveClient client={client} />);
    }

    const copyButton = () => {
        if (!filteredclients || filteredclients.length === 0) {
            console.log('No data to copy.');
            return;
        }

        let customizedData = filteredclients.map(client => {
            let sum = 0;

            for (let i = 0; i < project.transactions.length; i++) {
                const transaction = project.transactions[i];

                if (transaction.from === client.id) {
                    if (project.basecurrency === transaction.currencyid) {
                        sum -= Number(transaction.amount);
                    }
                }
            }

            for (let i = 0; i < project.journalentries.length; i++) {
                const rows = project.journalentries[i].rows;

                for (let j = 0; j < rows.length; j++) {
                    const row = rows[j];

                    if (row.accountid === client.id) {
                        sum += Number(row.debit);
                        sum -= Number(row.credit);
                    }
                }
            }

            return {
                name: client.name,
                balance: sum.toFixed(2),
            };
        });

        let tsvContent = Object.keys(customizedData[0]).join('\t') + '\n';
        tsvContent += customizedData.map(item => Object.values(item).join('\t')).join('\n');

        navigator.clipboard.writeText(tsvContent)
            .then(() => console.log('Customized data copied to clipboard'))
            .catch(err => console.error('Error copying to clipboard:', err));
    };

    const handleAccountStatement = (type, account, setOption) => {
        setOption(<StatementAccount type={type} account={account} setOption={setOption} />);
    }

    function AddClient() {
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addClient = (name) => {
            console.log('Trying to add client');

            if (!name) {
                setResult('Missing info');
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.clients.some(client => client.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Client with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CLIENT',
                            client: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Client</div>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addClient(name)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditClient({ client }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(client.id)
            setName(client.name)
        }, [client]);

        const updateClient = (id, name) => {
            console.log('Trying to update client');

            if (!name) {
                setResult('Missing info');
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.clients.some(client => client.name === trimmedName && client.id !== id);
            if (isNameDuplicate) {
                setResult('Client with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CLIENT',
                            client: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Client</div>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateClient(id, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(client)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveClient({ client }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(client.id)
            setName(client.name)
        }, [client]);

        const removeClient = (clientid) => {
            console.log('Trying to remove client');

            if (project.journalentries) {
                const hasJournalEntryData = project.journalentries.some((entry) => {
                    return entry.rows.some((row) => row.accountid === id);
                });
                if (hasJournalEntryData) {
                    setResult('Project has journal entry data related to ' + name);
                    return;
                }
            }

            if (project.readings) {
                const hasReadingData = project.readings.some((reading) => reading.rows.some((row) => row.clientid === id));
                if (hasReadingData) {
                    setResult('Project has reading data related to ' + name);
                    return;
                }
            }

            if (project.transactions) {
                const hasTransactionData = project.transactions.some((transaction) => transaction.from === id);
                if (hasTransactionData) {
                    setResult('Project has transaction data related to ' + name);
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: clientid
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CLIENT',
                            id: clientid
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeClient(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='clients'>
            <Title text='Clients' />
            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Client</button>
                    <button className='button' onClick={copyButton}>Copy to Clipboard</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <table className='table'>
                <thead><tr><th></th><th></th><th>Name</th><th>Balance</th></tr>
                </thead>
                <tbody>
                    {
                        clientsdata.map((client, index) => {
                            return (
                                <tr key={'client' + index}>
                                    <td><div className='table_button' onClick={() => editButton(client)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => handleAccountStatement('clients', { id: client.id, currencyid: project.basecurrency, name: client.name }, setOption)}>View</div></td>
                                    <td><div>{client.name}</div></td>
                                    <td><div className='clients_balance'>{client.currency} {client.balance.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Clients;