import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config';
import AdminUsers from './Users';

import Title from '../Title';

function Admin() {
    const [{ }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    return (
        <div className='admin'>
            <Title text='Admin' />

            <div className='buttonscontainer'>
                <button className='button' onClick={() => setOption(<AdminUsers />)}>Users</button>
            </div>

            {option}

        </div>
    );
}

export default Admin;