import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title';

import './PurchaseInvoices.css';

function PurchaseInvoices() {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredpurchaseinvoices, setFilteredPurchaseInvoices] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        const filtered = project.purchaseinvoices.filter((invoice) =>
            invoice.name.toLowerCase().includes(search.toLowerCase())
        );

        setFilteredPurchaseInvoices(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddPurchaseInvoice />);
    }

    const editButton = (purchaseinvoice) => {
        setOption(<EditPurchaseInvoice purchaseinvoice={purchaseinvoice} />);
    }

    const viewButton = (purchaseinvoice) => {
        setOption(<ViewPurchaseInvoice purchaseinvoice={purchaseinvoice} />);
    }

    const removeButton = (purchaseinvoice) => {
        setOption(<RemovePurchaseInvoice purchaseinvoice={purchaseinvoice} />);
    }


    function NewItem({ item, index, handleItemChange, handleQuantityChange, handleCostChange, handleRemoveItem }) {
        return (
            <tr>
                <td>
                    <select
                        className='modal_select'
                        value={item.itemid}
                        onChange={(e) => handleItemChange(e, index)}
                    >
                        <option value="">Select item</option>
                        {
                            project.inventory.map((item, index) => (
                                <option key={'item' + index} value={item.id}>
                                    {item.name}
                                </option>
                            ))
                        }
                    </select>
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemquantity}
                        onChange={(e) => handleQuantityChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemcost}
                        onChange={(e) => handleCostChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveItem(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddPurchaseInvoice() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{}]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setSuppliers(project.suppliers);
            setCurrencies(project.currencies);
        }, []);

        const handleCurrencyChange = (event) => {
            const selectedCurrency = event.target.value;
            setCurrencyid(selectedCurrency);
        };

        const handleAddItem = () => {
            setItems([...items, {}])
        };

        const handleItemChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemid = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = () => {
            if (items.length > 1) {
                const updatedItems = items.slice(0, items.length - 1);
                setItems(updatedItems);
            }
        };

        const totalAmount = items.reduce((total, item) => total + (item.itemquantity || 0) * (item.itemcost || 0), 0);

        const addPurchaseinvoice = (timestamp, supplierid, currencyid, name, items, amount) => {
            console.log('Trying to add purchase invoice');

            if (!supplierid || !currencyid || !name) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: user.userid,
                projectid: project.id,
                ts: timestamp,
                supplierid: supplierid,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Purchase Invoice</div>

                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />

                        <div className='modal_row'>
                            <select
                                className='modal_select'
                                value={supplierid}
                                onChange={(e) => setSupplierid(e.target.value)}
                            >
                                <option value="">Select supplier</option>
                                {
                                    suppliers.map((supplier, index) => (
                                        <option key={'supplier' + index} value={supplier.id}>
                                            {supplier.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={handleCurrencyChange}>
                                <option value="">Select a currency</option>
                                {
                                    currencies.map((currency, index) => (
                                        <option key={'currency' + index} value={currency.id}>
                                            {currency.symbol}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th></th></tr></thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <NewItem
                                        key={'item' + index}
                                        item={item}
                                        index={index}
                                        handleItemChange={handleItemChange}
                                        handleQuantityChange={handleQuantityChange}
                                        handleCostChange={handleCostChange}
                                        handleRemoveItem={handleRemoveItem}
                                    />
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalAmount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <div className='modal_button' onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addPurchaseinvoice(timestamp, supplierid, currencyid, name, items, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPurchaseInvoice({ purchaseinvoice }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{}]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(purchaseinvoice.id)
            setSelecteddate(new Date(purchaseinvoice.ts * 1000));
            setSuppliers(project.suppliers);
            setSupplierid(purchaseinvoice.supplierid);
            setCurrencies(project.currencies);
            setCurrencyid(purchaseinvoice.currencyid);
            setName(purchaseinvoice.name);
            setItems(purchaseinvoice.items);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleCurrencyChange = (event) => {
            const selectedCurrency = event.target.value;
            setCurrencyid(selectedCurrency);
        };

        const handleAddItem = () => {
            setItems([...items, {}])
        };

        const handleItemChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemid = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = () => {
            if (items.length > 1) {
                const updatedItems = items.slice(0, items.length - 1);
                setItems(updatedItems);
            }
        };

        const totalAmount = items.reduce((total, item) => total + (item.itemquantity || 0) * (item.itemcost || 0), 0);


        const updatePurchaseInvoice = (id, timestamp, supplierid, currencyid, name, items, amount) => {
            console.log('Trying to update purcahse invoice');

            if (!supplierid || !currencyid || !name) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: user.userid,
                projectid: project.id,
                id: id,
                ts: timestamp,
                supplierid: supplierid,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Purchase Invoice</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />

                        <div className='modal_row'>
                            <select
                                className='modal_select'
                                value={supplierid}
                                onChange={(e) => setSupplierid(e.target.value)}
                            >
                                <option value="">Select supplier</option>
                                {
                                    suppliers.map((supplier, index) => (
                                        <option key={'supplier' + index} value={supplier.id}>
                                            {supplier.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={handleCurrencyChange}>
                                <option value="">Select a currency</option>
                                {
                                    currencies.map((currency, index) => (
                                        <option key={'currency' + index} value={currency.id}>
                                            {currency.symbol}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th></th></tr></thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <NewItem
                                            key={'item' + index}
                                            item={item}
                                            index={index}
                                            handleItemChange={handleItemChange}
                                            handleQuantityChange={handleQuantityChange}
                                            handleCostChange={handleCostChange}
                                            handleRemoveItem={handleRemoveItem}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalAmount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <div className='modal_button' onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updatePurchaseInvoice(id, timestamp, supplierid, currencyid, name, items, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(purchaseinvoice)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemovePurchaseInvoice({ purchaseinvoice }) {
        const [id, setId] = useState(purchaseinvoice.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(purchaseinvoice.id)
        }, [purchaseinvoice]);

        const removePurchaseInvoice = (id) => {
            console.log('Trying to remove purchase invoice');

            setLoading(true);

            const data = {
                projectuserid: user.userid,
                projectid: project.id,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_PURCHASEINVOICE',
                            purchaseinvoiceid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Remove Purchase Invoice ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removePurchaseInvoice(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewPurchaseInvoice({ purchaseinvoice }) {
        const [timestamp, setTimestamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            setTimestamp(purchaseinvoice.ts);
            setSuppliers(project.suppliers);
            setSupplierid(purchaseinvoice.supplierid);
            setCurrencies(project.currencies);
            setCurrencyid(purchaseinvoice.currencyid);
            setName(purchaseinvoice.name);
            setItems(purchaseinvoice.items);
        }, [purchaseinvoice]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content' ref={printRef}>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_title'>Purchase Invoice</div>
                                <div className='modal_date'>{moment.unix(timestamp).format('dddd Do MMMM YYYY')}</div>
                            </div>
                            <div className='modal_rowsection'></div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Supplier</b> {suppliers.find(supplier => supplier.id === supplierid, 10)?.name || 'Supplier not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_rowsection'>{name}</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th></tr></thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <tr key={'item' + index}>
                                            <td>{project.inventory.find(inv => inv.id === item.itemid)?.name || 'Item not found'}</td>
                                            <td>{item.itemquantity}</td>
                                            <td>{currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemcost}</td>
                                            <td>{currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemquantity * item.itemcost}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {items.reduce((sum, item) => sum + ((item.itemcost || 0) * item.itemquantity), 0)}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='purchaseinvoices'>
            <Title text='Purchase Invoices' />
            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Purchase Invoice</button>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredpurchaseinvoices.map((purchaseinvoice, index) => (
                            <tr key={'purchaseinvoice' + index}>
                                <td><div className='table_button' onClick={() => editButton(purchaseinvoice, index)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(purchaseinvoice, index)}>View</div></td>
                                <td>{moment.unix(purchaseinvoice.ts).format('D/MMM/YYYY')}</td>
                                <td>{purchaseinvoice.name}</td>
                                <td>{project.currencies.find(item => item.id === purchaseinvoice.currencyid, 10)?.symbol || 'Currency not found'} {purchaseinvoice.amount}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default PurchaseInvoices;