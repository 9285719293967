import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';

import Header from './Header';
import Summary from './Summary';
import EditProject from './EditProject';
import CashAccounts from './CashAccounts';
import InteraccountTransfers from './InteraccountTransfers';
import Reconciliations from './Reconciliations';
import Settings from './Settings';
import Transactions from './Transactions';
import Clients from './Clients';
import Reports from './Reports';
import PurchaseInvoices from './PurchaseInvoices';
import SalesInvoices from './SalesInvoices';
import JournalEntries from './JournalEntries';
import ChartAccounts from './ChartAccounts';
import Suppliers from './Suppliers';
import Inventory from './Inventory';
import Quotations from './Quotations';
import Offers from './Offers';
import UserManagement from './UserManagement';

import './Home.css';

function Home() {
  const [{ user, project, version }, dispatch] = useStateValue();

  const [cashaccounts, setCashaccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const [interaccounttransfers, setInteraccounttransfers] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [reconciliations, setReconciliations] = useState([]);
  const [salesinvoices, setSalesinvoices] = useState([]);
  const [journalentries, setJournalentries] = useState([]);
  const [purchaseinvoices, setPurchaseinvoices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [offers, setOffers] = useState([]);

  const [option, setOption] = useState(<Summary />);

  const navigation = useNavigate();

  useEffect(() => {
    setCashaccounts(project.cashaccounts);
    setClients(project.clients);
    setInventory(project.inventory);
    setReconciliations(project.reconciliations)
    setSalesinvoices(project.salesinvoices);
    setInteraccounttransfers(project.interaccounttransfers);
    setJournalentries(project.journalentries);
    setPurchaseinvoices(project.purchaseinvoices);
    setSuppliers(project.suppliers);
    setTransactions(project.transactions);
    setQuotations(project.quotations);
    setOffers(project.offers);
  }, [project]);

  const uploadProject = () => {
    console.log('Trying to upload project')

    return;

    const data = {
      projectuserid: user.userid,
      projectpd: project
    }

    axios.post(configData.CONTROLLERURL + configData.UPLOADPROJECT, data, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "userid": user.userid,
        "usertoken": user.usertoken
      }
    }).then((res) => {
      console.log('Upload project data received')
      console.log(res.data)
      if (res.data instanceof Object) {
        if (res.data.code === 1) {
          navigation('/');
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const downloadBackup = () => {
    if (project) {
      project.version = version;
      const projectJSON = JSON.stringify(project);
      const blob = new Blob([projectJSON], { type: 'application/json' });

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const today = new Date().toLocaleDateString('en-GB');
      const formattedDate = today.replace(/\//g, '_');
      const formattedName = `${project.name.toLowerCase().replace(/\s+/g, '_')}_${formattedDate}`;
      a.download = formattedName + '.pd';
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  return (
    <div className='home'>
      <Header setOption={setOption} />
      <div className='home_projectdetails'>
        <div className='home_projectsection'>
          <div className='home_projecttext'>
            {project.name}
          </div>
        </div>
        {
          user.usertype == 1 || user.usertype == 2 && project.imported &&
          <div className='home_projectsection'>
            <button className='button' onClick={() => uploadProject()}>Save Project</button>
          </div>
        }
        {
          user.usertype == 1 || user.usertype == 2 &&
          <div className='home_projectsection'>
            <button className='button' onClick={() => setOption(<EditProject setOption={setOption} />)}>Edit</button>
          </div>
        }
        <div className='home_projectsection'></div>
        <div className='home_projectsection'></div>
        <div className='home_projectsection'>
          <div className='button' onClick={downloadBackup}>
            Backup
          </div>
        </div>
      </div>
      <div className='home_content'>
        <div className='home_menu'>
          <div className='home_button' onClick={() => setOption(<Summary />)}>Summary</div>
          <div className='home_button' onClick={() => setOption(<CashAccounts />)}>Cash Accounts ({cashaccounts.length})</div>
          <div className='home_button' onClick={() => setOption(<ChartAccounts />)}>Chart of Accounts</div>
          <div className='home_button' onClick={() => setOption(<Clients />)}>Clients ({clients.length})</div>
          <div className='home_button' onClick={() => setOption(<InteraccountTransfers />)}>Interaccount Transfers ({interaccounttransfers.length})</div>
          <div className='home_button' onClick={() => setOption(<Reconciliations />)}>Reconciliations ({reconciliations.length})</div>
          <div className='home_button' onClick={() => setOption(<Inventory />)}>Inventory ({inventory.length})</div>
          <div className='home_button' onClick={() => setOption(<JournalEntries />)}>Journal Entries ({journalentries.length})</div>
          <div className='home_button' onClick={() => setOption(<PurchaseInvoices />)}>Purchase Invoices ({purchaseinvoices.length})</div>
          <div className='home_button' onClick={() => setOption(<SalesInvoices />)}>Sales Invoices ({salesinvoices.length})</div>
          <div className='home_button' onClick={() => setOption(<Suppliers />)}>Suppliers ({suppliers.length})</div>
          <div className='home_button' onClick={() => setOption(<Transactions />)}>Transactions ({transactions.length})</div>
          <div className='home_button' onClick={() => setOption(<Quotations />)}>Quotations ({quotations.length})</div>
          <div className='home_button' onClick={() => setOption(<Offers />)}>Offers ({offers.length})</div>
          <div className='home_button' onClick={() => setOption(<Reports />)}>Reports</div>
          {user.usertype == 1 || user.usertype == 2 && (
            <div className='home_button' onClick={() => setOption(<UserManagement />)}>
              User Management
            </div>
          )}
          <div className='home_button' onClick={() => setOption(<Settings />)}>Settings</div>
        </div>
        <div className='home_panel'>
          {option}
        </div>
      </div>
    </div>
  );
}

export default Home;