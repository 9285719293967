import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config.json';

import { getPermission } from '../Utils/permissions.js';
import Title from '../Title';

function ProjectDetails() {
    const [{ user, project }, dispatch] = useStateValue();

    const [projectdetails, setProjectdetails] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        setProjectdetails(project.projectdetails);
    }, [project]);

    const handleProjectdetails = (event) => {
        setProjectdetails(event.target.value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
    };

    const handleSubmit = () => {
        console.log('Trying to add project details');
        setResult(null);

        const hasPermission = getPermission(project.projectuserid, project.users, user.userid, 'Settings', 'add');
        if (!hasPermission) {
            setResult('No permission');
            return;
        }

        setLoading(true);

        const formData = new FormData();

        formData.append('projectuserid', project.projectuserid);
        formData.append('projectid', project.projectid);
        formData.append('projectdetails', projectdetails);

        if (selectedImage) {
            formData.append('file', selectedImage);
        }

        axios.post(configData.CONTROLLERURL + configData.ADDPROJECTDETAILS, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Add project details data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Success');
                    let imageURL = res.data.projectdetailsimage;
                    dispatch({
                        type: 'ADD_PROJECTDETAILS',
                        projectdetails: projectdetails,
                        projectdetailsimage: imageURL
                    });
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleRemoveImage = () => {
        console.log('Trying to remove project details image');

        setResult(null);

        const hasPermission = getPermission(project.projectuserid, project.users, user.userid, 'Settings', 'remove');
        if (!hasPermission) {
            setResult('No permission');
            return;
        }

        setLoading(true);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
        }

        axios.post(configData.CONTROLLERURL + configData.REMOVEPROJECTDETAILSIMAGE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('remove project details image data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Success');
                    setSelectedImage(null);
                    inputRef.current.value = null;
                    dispatch({
                        type: 'REMOVE_PROJECTDETAILSIMAGE'
                    });
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className='projectdetails'>
            <Title text='Project Details' />
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <button className="settings_removebutton" onClick={handleRemoveImage}>Remove Image</button>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    {
                        selectedImage ? (
                            <div className='settings_imagecontainer'>
                                <img
                                    className='settings_image'
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Selected Business Image"
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            </div>
                        ) : (
                            project.projectdetailsimage &&
                            <div className='settings_imagecontainer'>
                                <img
                                    className='settings_image'
                                    src={project.projectdetailsimage}
                                    alt="Selected Business Image"
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <div className='settings_label'>Business image</div>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <div className='settings_inputimagecontainer'>
                        <input
                            className='settings_inputimage'
                            ref={inputRef}
                            type="file"
                            id="imageUploader"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        <label className='settings_uploadbutton' htmlFor="imageUploader">Upload Image</label>
                    </div>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <div className='settings_label'>(for best resolution 4000x1200 or aspect ratio 10:3)</div>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <div className='settings_label'>Business description</div>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <textarea
                        className='settings_textarea'
                        value={projectdetails}
                        onChange={handleProjectdetails}
                        placeholder="Enter business description..."
                        rows={5}
                        cols={50}
                    />
                </div>
            </div>
            <div className='result'>{result && result}</div>
            <div className='buttonscontainer'>
                <button className='button' onClick={handleSubmit} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
            </div>
        </div>
    );
}

export default ProjectDetails;