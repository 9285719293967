import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title';

import './InteraccountTransfers.css';

function InteraccountTransfers() {
    const [{ user, project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null);
    }

    const addInterButton = () => {
        setOption(<AddInteraccountTransfer />);
    }

    const editInterButton = (transfer) => {
        setOption(<EditInteraccountTransfer transfer={transfer} />);
    }

    const removeInterButton = (transfer) => {
        setOption(<RemoveInteraccountTransfer transfer={transfer} />);
    }

    const viewInterButton = (transfer) => {
        setOption(<ViewIneraccountTransfer transfer={transfer} />);
    }

    function AddInteraccountTransfer() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [name, setName] = useState('');

        const [fromcashaccounts, setFromcashaccounts] = useState([]);
        const [fromselectedcashaccount, setFromselectedcashaccount] = useState('');
        const [fromamount, setFromamount] = useState(0);

        const [tocashaccounts, setTocashaccounts] = useState([]);
        const [toselectedcashaccount, setToselectedcashaccount] = useState('');
        const [toamount, setToamount] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setFromcashaccounts(project.cashaccounts)
            setTocashaccounts(project.cashaccounts)
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);


        const addInterAccount = (ts, name, from, to, fromamount, toamount) => {
            console.log('Trying to add interaccount');

            if (!name || !fromselectedcashaccount || !toselectedcashaccount || isNaN(parseFloat(fromamount)) || isNaN(parseFloat(toamount)) || parseFloat(fromamount) <= 0 || parseFloat(toamount) <= 0) {
                setResult('Invalid data');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedname,
                from: from,
                to: to,
                fromamount: fromamount,
                toamount: toamount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDINTERACCOUNTTRANSFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add interaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_INTERACCOUNTTRANSFER',
                            interaccounttransfer: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Interaccount Transfer</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={fromselectedcashaccount} onChange={(e) => setFromselectedcashaccount(e.target.value)}>
                                    <option value="">Select account</option>
                                    {fromcashaccounts.map((account, index) => (
                                        <option key={'account' + index} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={fromamount}
                                    onChange={(e) => setFromamount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={toselectedcashaccount} onChange={(e) => setToselectedcashaccount(e.target.value)}>
                                    <option value="">Select account</option>
                                    {tocashaccounts.map((account, index) => (
                                        <option key={'account' + index} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={toamount}
                                    onChange={(e) => setToamount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addInterAccount(timestamp, name, fromselectedcashaccount, toselectedcashaccount, fromamount, toamount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditInteraccountTransfer({ transfer }) {
        const [id, setId] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [name, setName] = useState('');

        const [fromcashaccounts, setFromcashaccounts] = useState([]);
        const [fromselectedcashaccount, setFromselectedcashaccount] = useState('');
        const [fromamount, setFromamount] = useState(0);

        const [tocashaccounts, setTocashaccounts] = useState([]);
        const [toselectedcashaccount, setToselectedcashaccount] = useState('');
        const [toamount, setToamount] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setFromcashaccounts(project.cashaccounts);
            setTocashaccounts(project.cashaccounts);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transfer.id)
            setName(transfer.name);
            setFromselectedcashaccount(transfer.from);
            setToselectedcashaccount(transfer.to);
            setFromamount(transfer.fromamount)
            setToamount(transfer.toamount)
        }, [transfer]);

        const updateInterAccount = (id, ts, name, from, to, fromamount, toamount) => {
            console.log('Trying to update interaccount');

            if (!name || !fromselectedcashaccount || !toselectedcashaccount || isNaN(parseFloat(fromamount)) || isNaN(parseFloat(toamount)) || parseFloat(fromamount) <= 0 || parseFloat(toamount) <= 0) {
                setResult('Invalid data');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedname,
                from: from,
                to: to,
                fromamount: fromamount,
                toamount: toamount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEINTERACCOUNTTRANSFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update interaccount data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_INTERACCOUNTTRANSFER',
                            interaccounttransfer: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Interaccount Transfer</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={fromcashaccounts} onChange={(e) => setFromcashaccounts(e.target.value)}>
                                    {fromcashaccounts.map((account, index) => (
                                        <option key={'account' + index} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="From Amount"
                                    value={fromamount}
                                    onChange={(e) => setFromamount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={toselectedcashaccount} onChange={(e) => setToselectedcashaccount(e.target.value)}>
                                    {tocashaccounts.map((account, index) => (
                                        <option key={'account' + index} value={account.id}>
                                            {account.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="To Amount"
                                    value={toamount}
                                    onChange={(e) => setToamount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateInterAccount(id, timestamp, name, fromselectedcashaccount, toselectedcashaccount, fromamount, toamount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeInterButton(transfer)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveInteraccountTransfer({ transfer }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(transfer.id)
        }, [transfer]);

        const removeInterAccount = (id) => {
            console.log('Trying to remove interaccount');

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEINTERACCOUNTTRANSFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove inter accountdata received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_INTERACCOUNTTRANSFER',
                            interaccounttransferid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Remove Interaccount Transfer</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeInterAccount(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewIneraccountTransfer = ({ transfer }) => {
        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content' ref={printRef}>
                        <div className='modal_head'>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_subtitle'>No. <b>{transfer.id}</b></div>
                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_subtitle'>{project.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_center'>
                                    {project.currencies.find(item => item.id === transfer.currencyid)?.symbol || ''}{parseFloat(transfer.amount).toLocaleString('en-US')}
                                </div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Received from</b> {project.clients.find(item => item.id === transfer.from)?.name || 'Client not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>For</b> {transfer.name}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_signature'></div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_date'>{moment.unix(transfer.ts).format('dddd, Do MMMM YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='interaccountstransfer'>
            <Title text='Interaccount Transfers' />

            <div className='buttonscontainer'>
                <button className='button' onClick={addInterButton}>Add Interaccount Transfer</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Name</th><th>From</th><th>To</th><th>FromAmount</th><th>ToAmount</th></tr></thead>
                <tbody>
                    {
                        project.interaccounttransfers.map((transfer, index) => (
                            <tr key={'transfer' + index}>
                                <td><div className='table_button' onClick={() => editInterButton(transfer)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewInterButton(transfer)}>View</div></td>
                                <td>{moment.unix(transfer.ts).format('DD/MM/YYYY')}</td>
                                <td>{transfer.name}</td>
                                <td>
                                    {project.cashaccounts.find(item => item.id === transfer.from, 10)?.name || 'Account not found'}
                                </td>
                                <td>
                                    {project.cashaccounts.find(item => item.id === transfer.to, 10)?.name || 'Account not found'}
                                </td>
                                <td>{transfer.fromamount}</td>
                                <td>{transfer.toamount}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default InteraccountTransfers;