import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';

import Title from './Title';

import './ChartAccounts.css';

function ChartAccounts() {
    const [{ user, project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddAccount />)
    }

    const editButton = (account, type) => {
        setOption(<EditAccount account={account} type={type} />)
    }

    const removeButton = (account, type) => {
        setOption(<RemoveAccount account={account} type={type} />)
    }

    function AddAccount() {
        const [type, setType] = useState('incomeaccount');
        const [name, setName] = useState('');
        const [budget, setBudget] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAccount = (type, name, budget, currencyid) => {
            console.log('Trying to add account');

            if (!name || !currencyid) {
                setResult('Missing info')
                return;
            }

            if (isNaN(parseFloat(budget))) {
                setResult('Invalid budget amount');
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomeaccount') {
                const isAccountDuplicate = project.incomeaccounts.some(account => account.name === trimmedName);
                if (isAccountDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isAccountDuplicate = project.expenseaccounts.some(account => account.name === trimmedName);
                if (isAccountDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                budget: budget,
                currencyid: currencyid
            }

            axios.post(configData.CONTROLLERURL + configData.ADDACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'ADD_INCOMEACCOUNT',
                                incomeaccount: data
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'ADD_EXPENSEACCOUNT',
                                expenseaccount: data
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Type</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="incomeaccount">Income Account</option>
                                    <option value="expenseaccount">Expense Account</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Budget</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Budget"
                                    value={budget}
                                    onChange={(e) => setBudget(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={(e) => setCurrencyid(e.target.value)}>
                                <option value="">Select a currency</option>
                                {project.currencies.map((currency, index) => (
                                    <option key={'currency' + index} value={currency.id}>
                                        {currency.symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAccount(type, name, budget, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccount({ account, type }) {
        const [id, setId] = useState('');

        const [selectedtype, setSelectedtype] = useState('');
        const [name, setName] = useState('');
        const [budget, setBudget] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id);
            setSelectedtype(type);
            setName(account.name);
            setBudget(account.budget);
            setCurrencyid(account.currencyid)
        }, [account]);

        const updateAccount = (id, type, name, budget, currencyid) => {
            console.log('Trying to update account');

            if (!name || !currencyid) {
                setResult('Missing info')
                return;
            }

            if (isNaN(parseFloat(budget))) {
                setResult('Invalid budget amount');
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomeaccount') {
                const isAccountDuplicate = project.incomeaccounts.some(account => account.name === trimmedName && account.id !== id);
                if (isAccountDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isAccountDuplicate = project.expenseaccounts.some(account => account.name === trimmedName && account.id !== id);
                if (isAccountDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                budget: budget,
                currencyid: currencyid
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'UPDATE_INCOMEACCOUNT',
                                incomeaccount: data
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'UPDATE_EXPENSEACCOUNT',
                                expenseaccount: data
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Type</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={selectedtype}
                                    onChange={(e) => setSelectedtype(e.target.value)}
                                    disabled
                                >
                                    <option value="incomeaccount">Income Account</option>
                                    <option value="expenseaccount">Expense Account</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Budget</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Budget"
                                    value={budget}
                                    onChange={(e) => setBudget(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={(e) => setCurrencyid(e.target.value)}>
                                <option value="">Select a currency</option>
                                {project.currencies.map((currency, index) => (
                                    <option key={'currency' + index} value={currency.id}>
                                        {currency.symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateAccount(id, type, name, budget, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(account, type)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccount({ account, type }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
        }, [account]);

        const removeAccount = (id, type) => {
            console.log('Trying to remove account');

            if (project.journalentries) {
                const hasJournalEntryData = project.journalentries.some((entry) => {
                    return entry.rows.some((row) => row.accountid === id);
                });
                if (hasJournalEntryData) {
                    setResult('Project has journal entry data related to ' + name);
                    return;
                }
            }

            if (project.transactions) {
                const hasTransactionData = project.transactions.some((transaction) => transaction.to === id);
                if (hasTransactionData) {
                    setResult('Project has transaction data related to ' + name);
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'REMOVE_INCOMEACCOUNT',
                                incomeaccountid: id
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'REMOVE_EXPENSEACCOUNT',
                                expenseaccountid: id
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAccount(id, type)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='chartofaccounts'>
            <Title text='Chart Of Accounts' />

            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Account</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Name</th><th>Budget</th></tr></thead>

                <tbody>
                    <tr><td colSpan={3}><div className='chartaccounts_accounttitle'>Income</div></td></tr>
                    {
                        project.incomeaccounts.map((account, index) => {
                            let currencySymbol = project.currencies.find(item => item.id === account.currencyid, 10)?.symbol || 'Currency not found';

                            return (
                                <tr key={'account' + index}>
                                    <td><div className='table_button' onClick={() => editButton(account, 'incomeaccount')}>Edit</div></td>
                                    <td><div>{account.name}</div></td>
                                    <td><div>{currencySymbol} {account.budget.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })
                    }

                    <tr><td colSpan={3}><div className='chartaccounts_accounttitle'>Expense</div></td></tr>
                    {
                        project.expenseaccounts.map((account, index) => {
                            let currencySymbol = project.currencies.find(item => item.id === account.currencyid, 10)?.symbol || 'Currency not found';

                            return (
                                <tr key={'account' + index}>
                                    <td><div className='table_button' onClick={() => editButton(account, 'expenseaccount')}>Edit</div></td>
                                    <td><div>{account.name}</div></td>
                                    <td><div>{currencySymbol} {account.budget.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default ChartAccounts;