import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';

import Title from './Title';

import './Summary.css';

function findLatestRate(data, currencyid) {
    if (data.length === 0) {
        return 1;
    }

    let latestRate = 1;

    for (let i = 0; i < data.length; i++) {
        const entry = data[i];

        if (entry.currencyid === currencyid) {
            if (!latestRate || parseInt(entry.ts) > parseInt(latestRate.ts)) {
                latestRate = entry;
            }
        }
    }

    return latestRate ? latestRate.rate : 1;
}

function Summary() {
    const [{ project }, dispatch] = useStateValue();

    const totalInvoiceSum = project.salesinvoices.reduce((total, invoice) => {
        if (project.basecurrency === invoice.currencyid) {
            return total + parseFloat(invoice.amount);
        } else {
            const exchangeRate = findLatestRate(project.exchangerates, invoice.currencyid);
            const convertedAmount = parseFloat(invoice.amount) / exchangeRate;
            return total + convertedAmount;
        }
    }, 0);

    return (
        <div className='summary'>
            <Title text='Summary' />

            <div className='summary_columncontainer'>
                <div className='summary_column'>
                    <div className='summary_columntitle'>Balance Sheet</div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>Assets</div>
                        {project.cashaccounts.map((account, index) => {
                            let sum = 0;

                            for (let i = 0; i < project.transactions.length; i++) {
                                const transaction = project.transactions[i];

                                if (account.currencyid === transaction.currencyid) {
                                    if (transaction.to === account.id) {
                                        sum += parseFloat(transaction.amount);
                                    }
                                    if (transaction.from === account.id) {
                                        sum -= parseFloat(transaction.amount);
                                    }
                                }
                            }

                            return (
                                <div key={'account' + index}> {account.name} {project.currencies.find((item) => item.id === account.currencyid)?.symbol || 'Currency not found'}{' '}{sum.toLocaleString('en-US')}</div>
                            );
                        })}
                    </div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>Liabilities</div>
                    </div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>Equity</div>
                    </div>
                </div>
                <div className='summary_column'>
                    <div className='summary_columntitle'>Profit and Loss Statement</div>

                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>Income</div>
                        <div>Sales Invoices {project.currencies.find((currency) => currency.id === project.basecurrency)?.symbol} {totalInvoiceSum.toLocaleString('en-US')}</div>
                    </div>

                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>Expenses</div>
                        {
                            project.expenseaccounts.map((account, index) => {
                                let sum = 0;

                                for (let i = 0; i < project.transactions.length; i++) {
                                    const transaction = project.transactions[i];

                                    if (transaction.to === account.id) {
                                        if (project.basecurrency === transaction.currencyid) {
                                            sum += parseFloat(transaction.amount);
                                        }
                                        else {
                                            const exchangeRate = findLatestRate(project.exchangerates, transaction.currencyid);
                                            const convertedAmount = parseFloat(transaction.amount) / exchangeRate;
                                            sum += convertedAmount;
                                        }
                                    }
                                }

                                return (
                                    <div key={'account' + index} className='summary_expenseaccount'>{account.name} {project.currencies.find((currency) => currency.id === project.basecurrency)?.symbol} {sum.toLocaleString('en-US')}</div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Summary;