import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { isValidNumber } from './Utils/textparse.js'
import ClientSelect from './Components/clientselect.js';
import Title from './Title.js';

import { getPermission } from './Utils/permissions.js';
import './Offers.css';

function Offers() {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredoffers, setFilteredoffers] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.offers.filter((offer) =>
            offer.name.toLowerCase().includes(search.toLowerCase()) ||
            offer.id.includes(search) ||
            offer.amount.includes(search)
        );

        setFilteredoffers(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddOffer />);
    }

    const editButton = (offer) => {
        setOption(<EditOffer offer={offer} />);
    }

    const viewButton = (offer) => {
        setOption(<ViewOffer offer={offer} />);
    }

    const removeButton = (offer) => {
        setOption(<RemoveOffer offer={offer} />);
    }

    function NewItem({ item, index, handleNameChange, handleQuantityChange, handleCostChange, handleRemoveItem }) {
        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        value={item.itemname}
                        onChange={(e) => handleNameChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemquantity}
                        onChange={(e) => handleQuantityChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemcost}
                        onChange={(e) => handleCostChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveItem(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddOffer() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [clients, setClients] = useState([]);
        const [clientid, setClientid] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{ itemname: '', itemquantity: 0, itemcost: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setClients(project.clients);
            setCurrencies(project.currencies);
        }, [project]);

        const handleCurrencyChange = (event) => {
            const selectedCurrency = event.target.value;
            setCurrencyid(selectedCurrency);
        };

        const handleAddItem = () => {
            setItems([...items, { itemname: '', itemquantity: 0, itemcost: 0 }])
        };

        const handleNameChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemname = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = () => {
            if (items.length > 1) {
                const updatedItems = items.slice(0, items.length - 1);
                setItems(updatedItems);
            }
        };

        const totalAmount = items.reduce((total, item) => total + (item.itemquantity || 0) * (item.itemcost || 0), 0);

        const addOffer = (timestamp, clientid, currencyid, name, items, amount) => {
            console.log('Trying to add offer');

            const hasPermission = getPermission(project.projectuserid, project.users, user.userid, 'Offers', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !clientid || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                clientid: clientid,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDOFFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add offer data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_OFFER',
                            offer: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Offer</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />

                        <div className='modal_row'>
                            <ClientSelect clients={clients} clientid={clientid} setClientid={setClientid} width={200} />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={handleCurrencyChange}>
                                <option value="">Select a currency</option>
                                {
                                    currencies.map((currency, index) => (
                                        <option key={'currency' + index} value={currency.id}>
                                            {currency.symbol}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th></th></tr></thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <NewItem
                                        key={'item' + index}
                                        item={item}
                                        index={index}
                                        handleNameChange={handleNameChange}
                                        handleQuantityChange={handleQuantityChange}
                                        handleCostChange={handleCostChange}
                                        handleRemoveItem={handleRemoveItem}
                                    />
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalAmount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <div className='modal_button' onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addOffer(timestamp, clientid, currencyid, name, items, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditOffer({ offer }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [clients, setClients] = useState([]);
        const [clientid, setClientid] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{ itemname: '', itemquantity: 0, itemcost: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(offer.id)
            setSelecteddate(new Date(offer.ts * 1000));
            setClients(project.clients);
            setClientid(offer.clientid);
            setCurrencies(project.currencies);
            setCurrencyid(offer.currencyid);
            setName(offer.name);
            setItems(offer.items);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleCurrencyChange = (event) => {
            const selectedCurrency = event.target.value;
            setCurrencyid(selectedCurrency);
        };

        const handleAddItem = () => {
            setItems([...items, { itemname: '', itemquantity: 0, itemcost: 0 }])
        };

        const handleNameChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemname = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = () => {
            if (items.length > 1) {
                const updatedItems = items.slice(0, items.length - 1);
                setItems(updatedItems);
            }
        };

        const totalAmount = items.reduce((total, item) => total + (item.itemquantity || 0) * (item.itemcost || 0), 0);

        const updateOffer = (id, timestamp, clientid, currencyid, name, items, amount) => {
            console.log('Trying to update offer');

            const hasPermission = getPermission(project.projectuserid, project.users, user.userid, 'Offers', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !clientid || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                clientid: clientid,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEOFFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update offer data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_OFFER',
                            offer: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Offer</div>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                        <div className='modal_row'>
                            <ClientSelect clients={clients} clientid={clientid} setClientid={setClientid} width={200} />
                        </div>
                        <div className='modal_row'>
                            <select className='modal_select' value={currencyid} onChange={handleCurrencyChange}>
                                <option value="">Select a currency</option>
                                {
                                    currencies.map((currency, index) => (
                                        <option key={'currency' + index} value={currency.id}>
                                            {currency.symbol}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th></th></tr></thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <NewItem
                                            key={'item' + index}
                                            item={item}
                                            index={index}
                                            handleNameChange={handleNameChange}
                                            handleQuantityChange={handleQuantityChange}
                                            handleCostChange={handleCostChange}
                                            handleRemoveItem={handleRemoveItem}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalAmount}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <div className='modal_button' onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateOffer(id, timestamp, clientid, currencyid, name, items, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(offer)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveOffer({ offer }) {
        const [id, setId] = useState(offer.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeOffer = (id) => {
            console.log('Trying to remove offer');

            const hasPermission = getPermission(project.projectuserid, project.users, user.userid, 'Offers', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEOFFER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove offer data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_OFFER',
                            offerid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Remove Offer</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeOffer(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewOffer({ offer }) {
        const [timestamp, setTimestamp] = useState(0);

        const [clients, setClients] = useState([]);
        const [clientid, setClientid] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            setTimestamp(offer.ts);
            setClients(project.clients);
            setClientid(offer.clientid);
            setCurrencies(project.currencies);
            setCurrencyid(offer.currencyid);
            setName(offer.name);
            setItems(offer.items);
        }, [offer]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content' ref={printRef}>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                {
                                    project.projectdetailsimage &&
                                    <img
                                        className='projectdetailsimage'
                                        src={project.projectdetailsimage}
                                        alt="Project Details Image"
                                    />
                                }
                            </div>
                            <div className='modal_rowsection'>
                                <div className='modal_title'>Offer</div>
                                <div className='modal_date'>{moment.unix(timestamp).format('dddd Do MMMM YYYY')}</div>
                            </div>
                            <div className='modal_rowsection'></div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Client</b> {clients.find(client => client.id === clientid)?.name || 'Client not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_rowsection'>{name}</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th></tr></thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <tr key={'item' + index}>
                                            <td>{item.itemname}</td>
                                            <td>{item.itemquantity}</td>
                                            <td>{currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemcost}</td>
                                            <td>{currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemquantity * item.itemcost}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {items.reduce((sum, item) => sum + ((item.itemcost || 0) * item.itemquantity), 0)}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='offers'>
            <Title text='Offers' />
            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Offer</button>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>ID</th><th>Client</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredoffers.sort((a, b) => b.ts - a.ts).map((offer, index) => {
                            let clientName = project.clients.find(client => client.id === offer.clientid)?.name || '';
                            const currency = project.currencies.find(item => item.id === offer.currencyid, 10)?.symbol || '';
                            const amount = parseFloat(offer.amount).toLocaleString('en-US');

                            return (
                                <tr key={'offer' + index}>
                                    <td><div className='table_button' onClick={() => editButton(offer)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(offer)}>View</div></td>
                                    <td><div>{moment.unix(offer.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{offer.id}</div></td>
                                    <td><div>{clientName}</div></td>
                                    <td><div>{offer.name}</div></td>
                                    <td><div>{currency} {amount}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Offers;