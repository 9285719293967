import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';

import Title from './Title';
import StatementAccount from './StatementAccount';
import { getCashAccounts } from './Utils/cash.js';

import './CashAccounts.css';

function CashAccounts() {
    const [{ user, project }, dispatch] = useStateValue();
    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddAccount />);
    }

    const editButton = (account) => {
        setOption(<EditAccount account={account} />);
    }

    const removeButton = (account) => {
        setOption(<RemoveAccount account={account} />);
    }

    const handleAccountStatement = (type, account, setOption) => {
        setOption(<StatementAccount type={type} account={account} setOption={setOption} />);
    }

    function AddAccount() {
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [openingbalance, setOpeningbalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const handleCurrencyChange = (event) => {
            const selectedCurrency = event.target.value;
            setCurrencyid(selectedCurrency);
        };

        const addAccount = (name, currencyid, openingbalance) => {
            console.log('Trying to add account');

            if (!name || !currencyid || isNaN(parseFloat(openingbalance))) {
                setResult('Missing info')
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.cashaccounts.some(account => account.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Account with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName,
                currencyid: currencyid,
                openingbalance: openingbalance
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCASHACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CASHACCOUNT',
                            cashaccount: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={currencyid} onChange={handleCurrencyChange}>
                                    <option value="">Select a currency</option>
                                    {project.currencies.map((currency, index) => (
                                        <option key={'currency' + index} value={currency.id}>
                                            {currency.symbol}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Opening balance</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="0"
                                    value={openingbalance}
                                    onChange={(e) => setOpeningbalance(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAccount(name, currencyid, openingbalance)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccount({ account }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [openingbalance, setOpeningbalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
            setCurrencyid(account.currencyid)
            setOpeningbalance(account.openingbalance)
        }, [account]);

        const handleCurrencyChange = (event) => {
            const selectedCurrency = event.target.value;
            setCurrencyid(selectedCurrency);
        };

        const updateAccount = (id, name, currencyid, openingbalance) => {
            console.log('Trying to update account');

            if (!name || !currencyid || isNaN(parseFloat(openingbalance))) {
                setResult('Missing info')
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.cashaccounts.some(account => account.name === name && account.id !== id);
            if (isNameDuplicate) {
                setResult('Account with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName,
                currencyid: currencyid,
                openingbalance: openingbalance
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECASHACCOUNT, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                }
            }).then((res) => {
                console.log('Update account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CASHACCOUNT',
                            cashaccount: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={currencyid} onChange={handleCurrencyChange}>
                                    <option value="">Select a currency</option>
                                    {project.currencies.map((currency, index) => (
                                        <option key={'currency' + index} value={currency.id}>
                                            {currency.symbol}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Opening balance</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="0"
                                    value={openingbalance}
                                    onChange={(e) => setOpeningbalance(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateAccount(id, name, currencyid, openingbalance)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(account)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccount({ account }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
        }, [account]);

        const removeAccount = (id) => {
            console.log('Trying to remove account');

            if (project.interaccounttransfers) {
                const hasTransferFromData = project.interaccounttransfers.some((transfer) => transfer.from === id);
                const hasTransferToData = project.interaccounttransfers.some((transfer) => transfer.to === id);
                if (hasTransferFromData || hasTransferToData) {
                    setResult('Project has interaccount transfer data related to ' + name);
                    return;
                }
            }

            if (project.transactions) {
                const hasTransactionData = project.transactions.some((transaction) => transaction.from === id);
                if (hasTransactionData) {
                    setResult('Project has transaction data related to ' + name);
                    return;
                }
            }

            if (project.reconciliations) {
                const hasReconciliationData = project.reconciliations.some((reconciliation) => reconciliation.cashaccountid === id);
                if (hasReconciliationData) {
                    setResult('Project has reconciliation data related to ' + name);
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECASHACCOUNT, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                }
            }).then((res) => {
                console.log('Remove account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CASHACCOUNT',
                            cashaccountid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAccount(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const cashdata = getCashAccounts(project.cashaccounts, project.transactions, project.interaccounttransfers, project.currencies);

    return (
        <div className='accounts'>
            <Title text='Accounts' />

            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Account</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Name</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        cashdata.map((account, index) => {
                            return (
                                <tr className='table' key={'account' + index}>
                                    <td><div className='table_button' onClick={() => editButton(account)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => handleAccountStatement('cashaccounts', { id: account.id, currencyid: account.currencyid, name: account.name, openingbalance: account.openingbalance }, setOption)}>View</div></td>
                                    <td><div>{account.name}</div></td>
                                    <td><div>{account.currency} {account.balance.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default CashAccounts;