export const isRTL = (text) => {
    return /[\u0600-\u06FF]/.test(text);
};

export function areObjectsEqual(obj1, obj2) {
    const stringifiedObj1 = JSON.stringify(obj1);
    const stringifiedObj2 = JSON.stringify(obj2);

    return stringifiedObj1 === stringifiedObj2;
}

export const isValidNumber = (value) => /^-?\d*\.?\d+$/.test(value);

export function numberToWords(amount) {
    const converter = require('number-to-words');
    const words = converter.toWords(amount).split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}

export const hasMoreThanTwoDecimals = (number) => {
    return (number * 1000) % 10 !== 0;
};