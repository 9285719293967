import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config.json';
import Title from '../Title';
import '../Settings.css';

function Currency() {
    const [{ user, project }, dispatch] = useStateValue();
    const [suboption, setSuboption] = useState(null);

    const [makedefaultresult, setMakedefaultresult] = useState(null);
    const [makedefaultloading, setMakedefaultloading] = useState(false);

    const cancelButton = () => {
        setSuboption(null);
    }

    const addButton = () => {
        setSuboption(<AddCurrency />);
    }

    const editButton = (currency) => {
        setSuboption(<EditCurrency currency={currency} />);
    }

    const removeButton = (currency) => {
        setSuboption(<RemoveCurrency currency={currency} />);
    }

    const makeDefaultCurrency = (currencyid) => {
        console.log('Trying to make default currency');

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            id: currencyid,
        }

        setMakedefaultloading(true);

        axios.post(configData.CONTROLLERURL + configData.DEFAULTCURRENCY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": user.userid,
                "usertoken": user.usertoken
            }
        }).then((res) => {
            console.log('Default currency data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'MAKE_DEFAULT_CURRENCY',
                        currencyid: currencyid
                    });
                }
                else {
                    setMakedefaultresult(res.data.data)
                }
            }
            else {
                setMakedefaultresult('Error');
            }
        }).catch((err) => {
            setMakedefaultresult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setMakedefaultloading(false);
        });
    }

    function AddCurrency() {
        const [name, setName] = useState('');
        const [symbol, setSymbol] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addCurrency = (name, symbol) => {
            console.log('Trying to add currency');

            const isNameDuplicate = project.currencies.some(currency => currency.name === name);
            if (isNameDuplicate) {
                setResult('Currency with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: name,
                symbol: symbol,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCURRENCY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add currency data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CURRENCY',
                            currency: data
                        });
                        setSuboption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Currency</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Symbol"
                                    value={symbol}
                                    onChange={(e) => setSymbol(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addCurrency(name, symbol)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditCurrency({ currency }) {
        const [id, setId] = useState(currency.id);
        const [name, setName] = useState(currency.name);
        const [symbol, setSymbol] = useState(currency.symbol);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const updateCurrency = (id, name, symbol) => {
            console.log('Trying to update currency');

            const isNameDuplicate = project.currencies.some(currency => currency.name === name && currency.id !== id);
            if (isNameDuplicate) {
                setResult('Currency with the same name already exists');
                return;
            }
            
            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: name,
                symbol: symbol,
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECURRENCY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update currency data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CURRENCY',
                            currency: data
                        });
                        setSuboption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Currency</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Symbol"
                                    value={symbol}
                                    onChange={(e) => setSymbol(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateCurrency(id, name, symbol)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(currency)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveCurrency({ currency }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(currency.id)
            setName(currency.name)
        }, [currency]);

        const removeCurrency = (id) => {
            console.log('Trying to remove currency');

            if (project.journalentries) {
                const hasJournalEntryData = project.journalentries.some((entry) => entry.currencyid === id);
                if (hasJournalEntryData) {
                    setResult('Project has journal entry data related to ' + name);
                    return;
                }
            }

            if (project.transactions) {
                const hasTransactionData = project.transactions.some((transaction) => transaction.currencyid === id);
                if (hasTransactionData) {
                    setResult('Project has transaction data related to ' + name);
                    return;
                }
            }

            if (project.purchaseinvoices) {
                const hasPurchaseInvoiceData = project.purchaseinvoices.some((invoice) => invoice.currencyid === id);
                if (hasPurchaseInvoiceData) {
                    setResult('Project has purchase invoice data related to ' + name);
                    return;
                }
            }

            if (project.cashaccounts) {
                const hasCashAccountData = project.cashaccounts.some((cashaccount) => cashaccount.currencyid === id);
                if (hasCashAccountData) {
                    setResult('Project has cash account data related to ' + name);
                    return;
                }
            }

            if (project.exchangerates) {
                const hasExchangeRateData = project.exchangerates.some((exchangerate) => {
                    return exchangerate.fromcurrencyid === id || exchangerate.tocurrencyid === id;
                });
                if (hasExchangeRateData) {
                    setResult('Project has exchange rate data related to ' + name);
                    return;
                }
            }

            if (project.incomeaccounts) {
                const hasIncomeAccountData = project.incomeaccounts.some((incomeaccount) => {
                    return incomeaccount.currencyid === id;
                });
                if (hasIncomeAccountData) {
                    setResult('Project has budget account data related to ' + name);
                    return;
                }
            }

            if (project.expenseaccounts) {
                const hasExpenseAccountData = project.expenseaccounts.some((expenseaccount) => {
                    return expenseaccount.currencyid === id;
                });
                if (hasExpenseAccountData) {
                    setResult('Project has budget account data related to ' + name);
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECURRENCY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove currency data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CURRENCY',
                            currencyid: id
                        });
                        setSuboption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Remove Currency</div>
                        <div>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeCurrency(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='settings_currency'>
            <Title text='Currencies' />

            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>Add Currency</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Name</th><th>Symbol</th><th>Base</th></tr></thead>
                <tbody>
                    {
                        project.currencies.map((currency, index) => (
                            <tr key={'currency' + index}>
                                <td><div className='table_button' onClick={() => editButton(currency)}>Edit</div></td>
                                <td><div>{currency.name}</div></td>
                                <td><div>{currency.symbol}</div></td>
                                <td>
                                    <div>
                                        {
                                            project.basecurrency === currency.id ?
                                                'Default currency'
                                                :
                                                <button className="table_button" onClick={() => makeDefaultCurrency(currency.id)} disabled={makedefaultloading}>
                                                    {makedefaultloading ? 'Loading...' : 'Make default'}  {makedefaultresult && <div className='result'>{makedefaultresult}</div>}
                                                </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {suboption}

        </div>
    )
}

export default Currency;