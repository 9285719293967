import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';

import axios from 'axios';
import configData from './Config';

import Title from './Title';
import StatementAccount from './StatementAccount';
import { getSupplierAccounts } from './Utils/suppliers.js';

import './Suppliers.css';

function Suppliers() {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredsuppliers, setFilteredsuppliers] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    const suppliersdata = getSupplierAccounts(filteredsuppliers, project.transactions, project.purchaseinvoices, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.suppliers.filter((supplier) =>
            supplier.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredsuppliers(filtered)
    }, [project, search]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddSupplier />)
    }

    const editButton = (supplier) => {
        setOption(<EditSupplier supplier={supplier} />)
    }

    const removeButton = (supplier) => {
        setOption(<RemoveSupplier supplier={supplier} />)
    }

    const handleStatement = (type, account, setOption) => {
        setOption(<StatementAccount type={type} account={account} setOption={setOption} />)
    }

    function AddSupplier() {
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addSupplier = (name) => {
            console.log('Trying to add supplier');

            if (!name) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.suppliers.some(supplier => supplier.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Supplier with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDSUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Add supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_SUPPLIER',
                            supplier: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Add Supplier</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addSupplier(name)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditSupplier({ supplier }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        const updateSupplier = (id, name) => {
            console.log('Trying to update supplier');

            if (!name) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.suppliers.some(supplier => supplier.name === trimmedName && supplier.id !== id);
            if (isNameDuplicate) {
                setResult('Supplier with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATESUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Update supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_SUPPLIER',
                            supplier: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Edit Supplier</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateSupplier(id, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(supplier)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveSupplier({ supplier }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        const removeSupplier = (id) => {
            console.log('Trying to remove supplier');

            if (project.journalentries) {
                const hasJournalEntryData = project.journalentries.some((entry) => {
                    return entry.rows.some((row) => row.accountid === id);
                });
                if (hasJournalEntryData) {
                    setResult('Project has journal entry data related to ' + name);
                    return;
                }
            }

            if (project.transactions) {
                const hasTransactionData = project.transactions.some((transaction) => transaction.beneficiaryid === id);
                if (hasTransactionData) {
                    setResult('Project has transaction data related to ' + name);
                    return;
                }
            }

            if (project.purchaseinvoices) {
                const hasTransactionData = project.purchaseinvoices.some((invoice) => invoice.supplierid === id);
                if (hasTransactionData) {
                    setResult('Project has purchase invoice data related to ' + name);
                    return;
                }
            }

            if (project.contracts) {
                const hasContractsData = project.contracts.some((contract) => contract.supplierid === id);
                if (hasContractsData) {
                    setResult('Project has contract data related to ' + name);
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVESUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": user.userid,
                    "usertoken": user.usertoken
                }
            }).then((res) => {
                console.log('Remove supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_SUPPLIER',
                            supplierid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeSupplier(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='suppliers'>
            <Title text='Suppliers' />
            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Supplier</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <table className='table'>
                <thead><tr><th></th><th></th><th>Name</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        suppliersdata.map((supplier, index) => {
                            
                            return (
                                <tr key={'supplier' + index}>
                                    <td><div className='table_button' onClick={() => editButton(supplier)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => handleStatement('suppliers', { id: supplier.id, name: supplier.name, currencyid: project.basecurrency }, setOption)}>View</div></td>
                                    <td><div>{supplier.name}</div></td>
                                    <td><div>{supplier.currency} {supplier.balance.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Suppliers;