import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config';

import Title from '../Title';

function Profile() {
    const [{ user }, dispatch] = useStateValue();
    const [option, setOption] = useState(null);

    const [newusername, setNewusername] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [result, setResult] = useState('');

    useEffect(() => {
        setNewusername(user.username);
    }, [user]);

    const handleChangePassword = () => {
        console.log('Trying to change password');
        setResult(null)

        if (newPassword !== confirmPassword) {
            setResult('Passwords dont\'t match');
            return;
        }

        const data = {
            userid: user.userid,
            usertoken: user.usertoken,
            newpassword: newPassword
        }

        axios.post(configData.CONTROLLERURL + configData.CHANGEPASSWORD, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }).then((res) => {
            console.log('Change password data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult(res.data.data)
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        })
    };

    const handleChangeUsername = () => {
        console.log('Trying to change username');
        setResult(null)

        const data = {
            userid: user.userid,
            newusername: newusername,
        }

        axios.post(configData.CONTROLLERURL + configData.SAVEPROFILE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }).then((res) => {
            console.log('Change username data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'ADD_USERNAME',
                        username: newusername
                    });
                    setResult(res.data.data)
                }
                else {
                    setResult(res.data)
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        })
    };

    return (
        <div className='profile'>
            <Title text='Profile' />

            <p className='label'>Id: {user.userid}</p>
            <p className='label'>Username: {user.username}</p>
            <p className='label'>Email: {user.useremail}</p>

            <button onClick={() => setOption('Change Password')} className='button'>Change Password</button>
            <button onClick={() => setOption('Change Username')} className='button'>Change Username</button>

            {option === 'Change Password' && (
                <div className='profile_password-inputs'>
                    <input
                        type='password'
                        placeholder='New Password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='input'
                    />
                    <input
                        type='password'
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='input'
                    />
                    <button onClick={handleChangePassword} className='button'>Save Password</button>
                </div>
            )}

            {option === 'Change Username' && (
                <div className='profile_password-inputs'>
                    <input
                        type='text'
                        placeholder='New Username'
                        value={newusername}
                        onChange={(e) => setNewusername(e.target.value)}
                        className='input'
                    />
                    <button onClick={handleChangeUsername} className='button'>Save Username</button>
                </div>
            )}

            {result && <div className='result'>{result}</div>}
        </div>
    );
}

export default Profile;