import React from 'react';

import './UpdatePage.css'

function UpdatePage() {
    const handleRefresh = () => {
        window.location.reload(true);
    };

    return (
        <div className='updatepage'>
            <h1 className='updatepage_title'>Update Required</h1>
            <p className='updatepage_paragraph'>Your application is out of date. Please update to the latest version.</p>
            <button className='updatepage_button' onClick={handleRefresh}>Refresh</button>
        </div>
    );
}

export default UpdatePage;